import React from "react";

import classes from "./RangeFilters.module.css";
import { Divider } from "@mui/material";
import { useSelector } from "react-redux";
import { CompleteProductSelector } from "../../../../../../store/product/productDetails/productDetails.selectors";
import { capitalizeEachLetter } from "../../../../../../utils/helpers/strings";

const filtersValues: any = [
  {
    name: "All",
    value: "all",
  },
  {
    name: "4 weeks",
    value: "4 week",
  },
  {
    name: "8 weeks",
    value: "8 weeks",
  },
  {
    name: "12 weeks",
    value: "12 week",
  },
];

interface RangeFiltersProps {
  onDateChange: (item: any) => any;
  selectedPill: string;
  setSelectedPill: any;
}

const RangeFilters: React.FC<RangeFiltersProps> = (props) => {
  const { onDateChange, selectedPill, setSelectedPill } = props;
  const completeProduct = useSelector(CompleteProductSelector);

  const calculateDateRange = (filter: string): { startDate: Date; endDate: Date } => {
    const endDate = new Date(); // Current date
    let startDate = new Date();

    switch (filter) {
      case "all":
        startDate = new Date("2024-01-01");
        break;
      case "4 week":
        startDate.setDate(endDate.getDate() - 28); // 4 weeks = 28 days
        break;
      case "8 weeks":
        startDate.setDate(endDate.getDate() - 56); // 8 weeks = 56 days
        break;
      case "12 week":
        startDate.setDate(endDate.getDate() - 84); // 12 weeks = 84 days
        break;
      case "since live":
        const liveDate = new Date(completeProduct?.productLive?.lastLiveDate);
        if (!isNaN(liveDate.getTime())) {
          startDate = new Date(liveDate);
          startDate.setDate(startDate.getDate() - 7); // 1 week = 7 days
        } else {
          startDate = new Date("2024-01-01");
        }

        break;
      default:
        startDate = new Date("2024-01-01");
    }

    return { startDate, endDate };
  };

  const handlePill = (value: string) => {
    setSelectedPill(value);

    const { startDate, endDate } = calculateDateRange(value);
    console.log("Selected Filter:", value);
    console.log("Start Date:", startDate.toLocaleDateString());
    console.log("End Date:", endDate.toLocaleDateString());

    onDateChange({
      startDate,
      endDate,
    });
  };
  return (
    <div className={classes.Container}>
      <div className={classes.RangeText}>Range</div>
      {/* below is only for the products having lastLiveDate */}
      {completeProduct?.productLive?.lastLiveDate && (
        <div className="d-flex justify-content-center gap-2">
          <div
            onClick={() => handlePill("since live")}
            className={selectedPill === "since live" ? `${classes.PillActive} pe-none` : classes.PillInactive}
          >
            {"Since Live"}
          </div>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ opacity: 1, border: "1px solid #d9d9d9", height: "24px", mt: 0.5 }}
          />
        </div>
      )}
      {filtersValues.map((item: any) => (
        <div
          onClick={() => handlePill(item.value)}
          className={selectedPill === item.value ? `${classes.PillActive} pe-none` : classes.PillInactive}
          key={item.name}
        >
          {capitalizeEachLetter(item.name)}
        </div>
      ))}
    </div>
  );
};

export default RangeFilters;

import React from "react";
import classes from "./index.module.css";
import { capitalizeEachLetter } from "../../../utils/helpers/strings";
import MTypography from "../../Atoms/MTypography";
import { useDeviceType } from "../../../hooks/useDeviceType";
import ProductStatusCounter from "../../Organisms/SavedProducts/SavedProductHeader/ProductStatusCounter";

interface TabsToolbarProps {
  title: string;
  subTitle?: string;
  infoIcon?: React.ReactNode;
  children?: React.ReactNode;
  avoidCapitalization?: boolean;
  isStatusCounter?: boolean
}

const TabsToolbar: React.FC<TabsToolbarProps> = ({
  title,
  children,
  infoIcon,
  subTitle,
  avoidCapitalization = false,
  isStatusCounter = false
}) => {
  const { isDesktop } = useDeviceType();
  return (
    <div className={`d-flex justify-content-between align-items-center ${classes.Container}`}>
      <div className={`d-flex align-items-center ${isDesktop && "gap-3"}`}>
        <MTypography variant={"h3"} customClass={classes.Heading}>
          {avoidCapitalization ? title : capitalizeEachLetter(title.split("-").join(" "))}
        </MTypography>
        {subTitle && (
          <MTypography variant={"subtitle1"} color="#80828A" customClass={"pt-2"}>
            {subTitle}
          </MTypography>
        )}
        {infoIcon}
        <div className={'ml-2'}>
          {isDesktop && isStatusCounter && <ProductStatusCounter />}
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default TabsToolbar;

import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

import KeywordHistory from "../../../Molecules/KeywordHistory";
import MTypography from "../../../Atoms/MTypography";
import PercentageTag from "../../../Atoms/PercentageTag";

import classes from "./index.module.css";
import { AsinKeywordHistoryObject } from "../../../../store/product/product.reducers";
import { useDeviceType } from "../../../../hooks/useDeviceType";

import CheckedIcon from "../../../../assets/svgs/Checkbox/Square/rowSelectIcon.svg";
import UnCheckedIcon from "../../../../assets/svgs/Checkbox/Square/rowUnSelectIcon.svg";
import { Tooltip } from "@mui/material";

interface Props {
  text: string;
  index: number;
  isSelected?: boolean;
  isSeoKeyword: boolean;
  searchVolume: number | null;
  competitiveness: number | null;
  isObject?: boolean;
  keywordHistory: AsinKeywordHistoryObject[] | null;
  rank?: number;
  id?: string;
  item?: any;
  selectedItem?: any;
  handleSelected?: any;
  keywordType?: string;
  titleRarirty?: number | null;
}

const Index: React.FC<Props> = ({
  keywordHistory,
  searchVolume = 0,
  text,
  index,
  competitiveness,
  titleRarirty,
  isObject = false,
  rank = 0,
  selectedItem = {},
  id = "",
  handleSelected,
  item,
  keywordType = "",
}) => {
  const { isDesktop } = useDeviceType();

  const [isOpen, setIsOpen] = useState(false);

  const isSelected = () => {
    let result: any = null;
    if (id === "restKeywords" || id === "selling") {
      result = selectedItem[id].list.find((keyword: any) => keyword === item);
    } else {
      result = selectedItem[id].list.find((keyword: any) => keyword?.phrase === item?.phrase);
    }

    return !!result;
  };

  const onSelectedItem = (isSelected = false) => {
    handleSelected({
      id,
      item,
      isSelected,
    });
  };

  const onMouseEnter = () => {
    if (text.length > 50) {
      setIsOpen(true);
    }
  };

  return (
    <>
      {isDesktop ? (
        <div className={`col-lg-6 col-md-6 col-xs-12 col-sm-12`}>
          <Draggable draggableId={`${text}-${isObject ? "object" : "string"}-${keywordType}`} index={index}>
            {(provided: any, snapshot: any) => (
              <div
                className={`d-flex flex-row gap-1 py-1 ${classes.IsSelected} ${classes.Container}`}
                ref={provided.innerRef}
                {...provided.draggableProps}
                isDragging={snapshot.isDragging}
                {...provided.dragHandleProps}
              >
                <div>
                  <DragIndicatorIcon className={classes.DragIcon} />
                </div>
                <Tooltip
                  open={isOpen}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={() => setIsOpen(false)}
                  placement={"top"}
                  arrow
                  title={text}
                >
                  <div className={"d-flex flex-column gap-1  flex-grow-1"}>
                    <KeywordHistory keywordHistory={keywordHistory ? keywordHistory : []}>
                      <div>
                        <MTypography variant={"subtitle1"} customClass={classes.Label}>
                          {text}
                        </MTypography>
                      </div>
                    </KeywordHistory>
                    <div className={"d-flex flex-row mt-1"}>
                      <div className={`d-flex flex-column w-33  ${classes.Padding8}`}>
                        <MTypography variant={"subtitle1"} customClass={classes.SearchVolume}>
                          {searchVolume !== null && searchVolume !== 0 ? searchVolume.toLocaleString() : "--"}
                        </MTypography>
                        <MTypography variant={"subtitle1"} customClass={classes.CaptionLabel}>
                          Search Volume
                        </MTypography>
                      </div>

                      <div className={"d-flex flex-column w-33"}>
                        <div className={"flex"}>
                          {competitiveness || competitiveness === 0 ? (
                            <PercentageTag percentage={competitiveness} />
                          ) : (
                            <MTypography variant={"subtitle1"} customClass={classes.SearchVolume}>
                              --
                            </MTypography>
                          )}
                        </div>
                        <MTypography variant={"caption"} customClass={classes.CaptionLabel}>
                          {/* Competitiveness Score */}
                          Rank Ease
                        </MTypography>
                      </div>
                      <div className={"d-flex flex-column w-33"}>
                        <div className={"flex"}>
                          {titleRarirty || titleRarirty === 0 ? (
                            <PercentageTag percentage={titleRarirty} />
                          ) : (
                            <MTypography variant={"subtitle1"} customClass={classes.SearchVolume}>
                              --
                            </MTypography>
                          )}
                        </div>
                        <MTypography variant={"caption"} customClass={classes.CaptionLabel}>
                          Title Rarity
                        </MTypography>
                      </div>
                    </div>
                  </div>
                </Tooltip>
                {!!rank && rank !== 999 && (
                  <div className={classes.CurrentRank}>
                    <MTypography variant={"subtitle1"} customClass={classes.Rank}>
                      {`Rank`}
                    </MTypography>
                    <MTypography variant={"subtitle1"} customClass={classes.RankValue}>
                      {`${rank}`}
                    </MTypography>
                  </div>
                )}
              </div>
            )}
          </Draggable>
        </div>
      ) : (
        <div className={`col-lg-6 col-md-6 col-xs-12 col-sm-12`}>
          <div
            className={`d-flex flex-row gap-2 py-2 ${classes.IsSelected} ${classes.Container}`}
            onClick={() => onSelectedItem(isSelected())}
          >
            <div>{isSelected() ? <img src={CheckedIcon} alt="" /> : <img src={UnCheckedIcon} alt="" />}</div>

            <div className={"d-flex flex-column gap-1  flex-grow-1"}>
              <KeywordHistory keywordHistory={keywordHistory ? keywordHistory : []}>
                <div>
                  <MTypography variant={"subtitle1"} customClass={classes.Label}>
                    {text}
                  </MTypography>
                  <div className={classes.Divider}></div>
                </div>
              </KeywordHistory>

              <div className={`d-flex flex-row  ${classes.Gap1}`}>
                <div className={"d-flex flex-column"}>
                  <MTypography variant={"subtitle1"} customClass={classes.SearchVolume}>
                    {searchVolume !== null && searchVolume !== 0 ? searchVolume.toLocaleString() : "--"}
                  </MTypography>
                  <MTypography variant={"subtitle1"} customClass={classes.CaptionLabel}>
                    Search Volume
                  </MTypography>
                </div>

                <div className={"d-flex flex-column"}>
                  <div className={"flex"}>
                    {competitiveness || competitiveness === 0 ? (
                      <PercentageTag percentage={competitiveness} />
                    ) : (
                      <MTypography variant={"subtitle1"} customClass={classes.SearchVolume}>
                        --
                      </MTypography>
                    )}
                  </div>
                  <MTypography variant={"caption"} customClass={classes.CaptionLabel}>
                    Rank Ease
                  </MTypography>
                </div>
                <div className={"d-flex flex-column"}>
                  <div className={"flex"}>
                    {titleRarirty || titleRarirty === 0 ? (
                      <PercentageTag percentage={titleRarirty} />
                    ) : (
                      <MTypography variant={"subtitle1"} customClass={classes.SearchVolume}>
                        --
                      </MTypography>
                    )}
                  </div>
                  <MTypography variant={"caption"} customClass={classes.CaptionLabel}>
                    Title Rarity
                  </MTypography>
                </div>
              </div>
            </div>

            {!!rank && rank !== 999 && (
              <div className={classes.CurrentRank}>
                <MTypography variant={"subtitle1"} customClass={classes.Rank}>
                  {`Rank`}
                </MTypography>
                <MTypography variant={"subtitle1"} customClass={classes.RankValue}>
                  {`${rank}`}
                </MTypography>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Index;

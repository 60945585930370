import axios from "../axios";
import { errorHandler } from "../utils/helpers/apis";

export const amazonAuthorizeApi = {
  async loginAmazon({ spapi_oauth_code, state, selling_partner_id, partner_type }: any) {
    return axios
      .get<any, any>(`/sellerpartner/login?spapi_oauth_code=${encodeURIComponent(spapi_oauth_code)}&state=${encodeURIComponent(state)}&selling_partner_id=${encodeURIComponent(selling_partner_id)}&partner_type=${encodeURIComponent(partner_type)}`)
      .then((r: any) => r.data.data)
      .catch(errorHandler);
  },
  // this is only for showing consoles to backend
  async logAmazon(body: any) {
    return axios
      .post<any, any>(`/sellerpartner/log`, body)
      .then((r: any) => r.data.data)
      .catch(errorHandler);
  },
};

import React, { useEffect, useMemo, useState } from "react";
import classes from "./index.module.css";
import {
  ContentStatusEnum,
  CountryDomainEnum,
  ParamsEnum,
  ProductLiveStatusEnum,
  RolesEnum,
  TabsEnum,
} from "../../../utils/constants/enums";
import MTypography from "../../../components/Atoms/MTypography";
import ProductDetailsCompetitors from "../../../components/Organisms/ProductDetails/ProductDetailsCompetitors";
import ProductDetailsKeywords from "../../../components/Organisms/ProductDetails/ProductdetailsKeywords";
import InfoKpiBoxes from "../../../components/Atoms/InfoKpiBoxes";
import ProductDetailsSwipeableTabs from "../../../components/Organisms/ProductDetails/ProductDetailsSwipeableTabs";
import LinkAsin from "../../../components/Organisms/ProductDetails/LinkAsin";

import ProductDetailBsr from "../../../components/Organisms/ProductDetails/ProductDetailBsr";
import { toast } from "react-toastify";
import { errorHandler } from "../../../utils/helpers/apis";
import { useDispatch, useSelector } from "react-redux";
import MainLoader from "../../../components/Atoms/MainLoader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  calculateChangeOverAvgCompetitors,
  calculatePercentageChange,
  checkValidObj,
  handleChangeKeywordsClick,
  handleChangeValue,
  productInactiveCount,
  showChangeKeywordButton,
  showIncompleteBullet,
  showProductLiveStatus,
} from "./config";
import {
  showApprovedButtonOnDetailPage,
  showApprovedButtonOnDetailPageCompany,
  showUpgradeOnProductDetailPage,
  showUpgradeOnProductDetailPageCompany,
  userRole,
  showUnApprovedButtonOnDetailPageCompany,
  showUnApprovedButtonOnDetailPage,
  showPublishOnAmazonButton,
  showPublishOnAmazonButtonCompany,
} from "../../../utils/helpers/priviligesChecks";
import { DbUserSelector } from "../../../store/user/user.selector";
import { formatNumberWithCommas } from "../../../utils/helpers/numbers";
import { useDeviceType } from "../../../hooks/useDeviceType";
import backIcon from "../../../assets/svgs/back-icon.svg";
import keywordIcon from "../../../assets/svgs/keywords_icon.svg";
// import competitorIcon from "../../../assets/svgs/competitor_icon.svg";

import MBottomSheet from "../../../components/Atoms/MBottomSheet";
import CloseIcon from "@mui/icons-material/Close";
import { CountryDomainObject, getCountryDomainObjectByName } from "../../../components/Organisms/DomainDropDown/config";
import FeedbackDialogue from "../../../components/mui/dialogebox/feedbackDialgoebox";
import { SUCC_DELETE_PRODUCT } from "../../../utils/constants/messages/success";
import { generateProductApis } from "../../../apis/generate-product";
import { KeywordsApis } from "../../../apis/keywords";
import { defaultImage } from "../../../utils/constants/general-constants";
import InCompleteProductError from "../../../components/Organisms/ProductDetails/InCompleteProductError";
import ProductStatus from "../../../components/Molecules/ProductStatus";
import { capitalizeEachLetter, formatBulletsPoints } from "../../../utils/helpers/strings";
import MButton from "../../../components/Atoms/MButton";
import { CompleteProductSelector } from "../../../store/product/productDetails/productDetails.selectors";
import {
  approveProductContentAction,
  getCompleteProductAction,
  getProductLiveCheckAction,
} from "../../../store/product/productDetails/productDetails.actions";

import { URL_OPPORTUNITY_REPORT, URL_PRODUCTS } from "../../../routes/routes-path";
import ActionBox from "../../../components/Molecules/ActionBox";
import { navigatePage } from "../../../utils/helpers/common";
import { setChangeKeywordsProductOwnerIdDispatch } from "../../../store/product/product.actions";
import { getScrapeProductAction } from "../../../store/product/productScrape/productScrape.actions";
import UpgradeNowBannerDetailsPage from "../../../components/Organisms/ProductDetails/UpgradeNowBannerDetailsPage/UpgradeNowBannerDetailsPage";
import InfoKpiChangeBox from "../../../components/Atoms/InfoKpiChangeBox";
import { onImgErrorHandler } from "../../../utils/helpers/general-helpers";
import { CompanyRoleSelector, CompanySelector } from "../../../store/company/company.selector";
import DetailsActionsMenu from "../../../components/Organisms/ProductDetails/DetailsActionsMenu/DetailsActionsMenu";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MuiTooltip from "../../../components/Atoms/MuiTooltip";
import { fetchAmazonASIN } from "../../../utils/helpers/regex";
import { ScrapeProductSelector } from "../../../store/product/productScrape/productScrape.selectors";
import MRating from "../../../components/Atoms/MRating";
import { ProductApis } from "../../../apis/product";
import { Grid, Tooltip } from "@mui/material";
import ProductEventsHistory from "../../../components/Organisms/ProductDetails/ProductEventsHistory";
import { getSelectedTitleVariation } from "../../../components/Organisms/ProductDetails/ProductDetailsSwipeableTabs/ProductDetailsTab/config";
import { UserApis } from "../../../apis/user";
import { setCompanyAction } from "../../../store/company/company.actions";
import { setUserDispatch } from "../../../store/user/user.actions";
import { ERR_AMAZON_AUTHORIZATION } from "../../../utils/constants/messages/errors";

export interface ProductDetailsState {
  loading: boolean;
  showLive: boolean;
  openKeywordsBottomSheet: boolean;
  openCompetitorsBottomSheet: boolean;
  feedbackBox: boolean;
  openDeleteConfirm: boolean;
  keywordsLoading: boolean;
  openConfirmApprove: boolean;
  approveLoading: boolean;
  openPopper: boolean;
  anchorEl: SVGSVGElement | null;
}

const ProductDetails = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const currentCompany = useSelector(CompanySelector);
  const companyRole = useSelector(CompanyRoleSelector);
  const scrapeProduct = useSelector(ScrapeProductSelector);

  const currentUser = useSelector(DbUserSelector);
  const completeProduct = useSelector(CompleteProductSelector);
  const { isDesktop } = useDeviceType();

  const productAsin = completeProduct?.productASIN;
  const isManualProduct = fetchAmazonASIN(productAsin || "") ? true : false;

  const [state, setState] = useState<ProductDetailsState>({
    loading: false,
    showLive: false,
    openKeywordsBottomSheet: false,
    openCompetitorsBottomSheet: false,
    feedbackBox: false,
    openDeleteConfirm: false,
    keywordsLoading: false,
    openConfirmApprove: false,
    approveLoading: false,
    openPopper: false,
    anchorEl: null,
  });

  const [openUnapprove, setOpenUnapprove] = useState<boolean>(false);
  const [refetchProductDetail, setRefetchProductDetail] = useState<boolean>(false);

  const countryDomainObject = getCountryDomainObjectByName(
    completeProduct?.domain || CountryDomainEnum.AMAZON_USA
  ) as CountryDomainObject;

  const percentageChange = calculatePercentageChange(
    completeProduct?.status === ContentStatusEnum.EDITED &&
      completeProduct?.editedGeneratedProductContent?.newProductContentScore
      ? completeProduct?.editedGeneratedProductContent?.existingProductContentScore?.cumulativeSearchVolume
      : completeProduct?.existingProductContentScore?.cumulativeSearchVolume,
    completeProduct?.status === ContentStatusEnum.EDITED &&
      completeProduct?.editedGeneratedProductContent?.newProductContentScore
      ? completeProduct?.editedGeneratedProductContent?.newProductContentScore?.cumulativeSearchVolume
      : completeProduct?.newProductContentScore?.cumulativeSearchVolume
  );

  const changeOverCompetitors = calculateChangeOverAvgCompetitors(
    completeProduct?.generatedContentCompetitors,
    completeProduct?.status === ContentStatusEnum.EDITED &&
      completeProduct?.editedGeneratedProductContent?.newProductContentScore
      ? completeProduct?.editedGeneratedProductContent?.newProductContentScore?.cumulativeSearchVolume
      : completeProduct?.newProductContentScore?.cumulativeSearchVolume
  );

  const beforeValue = formatNumberWithCommas(
    completeProduct?.status === ContentStatusEnum.EDITED &&
      completeProduct?.editedGeneratedProductContent?.newProductContentScore
      ? completeProduct?.editedGeneratedProductContent?.existingProductContentScore?.cumulativeSearchVolume
      : completeProduct?.existingProductContentScore?.cumulativeSearchVolume || "0"
  );

  const afterValue = formatNumberWithCommas(
    completeProduct?.status === ContentStatusEnum.EDITED &&
      completeProduct?.editedGeneratedProductContent?.newProductContentScore
      ? completeProduct?.editedGeneratedProductContent?.newProductContentScore?.cumulativeSearchVolume
      : completeProduct?.newProductContentScore?.cumulativeSearchVolume || "0"
  );
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    // to clear the state
    dispatch(setChangeKeywordsProductOwnerIdDispatch(null));

    localStorage.removeItem("productOwnerId");
  }, []);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (productAsin && completeProduct.hasOwnProperty("domain")) {
      dispatch(
        getScrapeProductAction({
          domain: completeProduct?.domain || "",
          asin: params?.asin || "",
          isGoGoAutomato: false,
          userID: completeProduct?.userID,
        })
      );
    }
  }, [productAsin]);

  const getCompleteProduct = () => {
    setState({ ...state, loading: true });
    dispatch(
      getCompleteProductAction({
        productASIN: params[ParamsEnum.ASIN] as string,
        userID: params[ParamsEnum.USER_ID] as string,
      })
    )
      .then(() => {
        setState({ ...state, loading: false });
      })
      .catch((e: any) => {
        toast.error(errorHandler(e));
        setState({ ...state, loading: false });
      });
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getCompleteProduct();
  }, [refetchProductDetail]);

  const openKeywordsBottomSheet = () => {
    setState({ ...state, openKeywordsBottomSheet: true });
  };
  const closeKeywordBottomSheet = () => {
    setState({ ...state, openKeywordsBottomSheet: false, openCompetitorsBottomSheet: false });
  };

  const handleOpenUnapprove = () => setOpenUnapprove(true);
  const handleLiveCheck = () => {
    if (state.showLive) {
      toast.warning("Please Wait");
    } else {
      setState({ ...state, showLive: true, openPopper: false });
      dispatch(getProductLiveCheckAction(completeProduct))
        .then(() => {
          setState({ ...state, showLive: false, openPopper: false });
        })
        .catch((e: any) => {
          toast.error(errorHandler(e));
          setState({ ...state, showLive: false, openPopper: false });
        });
    }
  };
  const handleFeedbackClick = () => {
    setState({ ...state, feedbackBox: true, openPopper: false });
  };

  const handleBack = () => {
    if (location.state?.isChildComponent === TabsEnum.SAVED_ASIN) {
      navigatePage(URL_PRODUCTS, navigate);
    } else if (location.state?.isChildComponent === TabsEnum.SAVED) {
      const brandName = completeProduct?.brand ? completeProduct?.brand?.toLowerCase() : "others";
      navigatePage(`${URL_PRODUCTS}/${brandName}`, navigate, { state: { productBrand: brandName } });
    } else if (location.state?.isChildComponent === TabsEnum.OPPORTUNITY_REPORT) {
      // handling nav from oppor report
      const brand = location?.state?.productBrand;
      if (brand) {
        navigatePage(`${URL_OPPORTUNITY_REPORT}/${brand}`, navigate, { state: { productBrand: brand } });
      } else {
        navigatePage(URL_OPPORTUNITY_REPORT, navigate);
      }
    } else {
      if (location?.state?.isChildComponent) {
        navigate(location?.state?.isChildComponent);
      } else {
        navigatePage(URL_PRODUCTS, navigate);
      }
    }
  };

  const handleDelete = () => {
    setState({ ...state, openDeleteConfirm: true, openPopper: false });
  };
  const cancelDelete = () => {
    setState({ ...state, openDeleteConfirm: false });
  };
  const confirmDelete = async () => {
    try {
      await generateProductApis.deleteGeneratedProducts([completeProduct?.id]);
      toast.success(SUCC_DELETE_PRODUCT);
      handleBack();
    } catch (e) {
      errorHandler(e);
    }
  };

  const triggerKeywordTracking = async () => {
    if (state.keywordsLoading) {
      toast.warning("Please Wait");
    } else {
      setState({ ...state, keywordsLoading: true, openPopper: false });
      KeywordsApis.triggerKeywordTracking({
        productASIN: completeProduct?.productASIN,
        userID: completeProduct?.userID,
        finalizedHelium10Keywords: completeProduct.selectedHelium10Keywords.map((keyword) => keyword?.phrase || ""),
      })
        .then(() => {
          setState({ ...state, keywordsLoading: false, openPopper: false });
          toast.success("Keyword tracking successfully activated.");
        })
        .catch((e: any) => {
          toast.error(errorHandler(e));
          setState({ ...state, keywordsLoading: false, openPopper: false });
        });
    }
  };
  const openApprove = () => setState({ ...state, openConfirmApprove: true });

  const cancelApprove = () => setState({ ...state, openConfirmApprove: false });

  const cancelUnapprove = () => setOpenUnapprove(false);
  const confirmApprove = async () => {
    setState({ ...state, approveLoading: true });

    dispatch(
      approveProductContentAction({
        ...completeProduct,
        isUnApprove: openUnapprove,
      })
    )
      .then(() => {
        setState({ ...state, approveLoading: false, openConfirmApprove: false });
        setOpenUnapprove(false);
        toast.success(openUnapprove ? "Product content has been unapproved" : "Product content has been approved");
      })
      .catch((e: any) => {
        setState({ ...state, approveLoading: false, openConfirmApprove: false });
        toast.error(errorHandler(e));
      });
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  const productDetailBsr = useMemo(() => {
    console.log("test pick product", productAsin);
    return <ProductDetailBsr product={completeProduct} />;
  }, [productAsin]);

  const blurString = (inputString: string) => {
    if (inputString?.length <= 44) {
      return <span>{inputString}</span>;
    }

    let firstPart = inputString?.substring(0, 44);
    let remainingPart = inputString?.substring(44);
    // let blurredPart = '0'.repeat(remainingPart?.length || 0);
    let blurredPart = remainingPart
      ?.split("")
      .map((char) => (char === " " ? " " : "0"))
      .join("");
    const makeContentBlur = currentUser?.companyID
      ? currentCompany?.role === RolesEnum.TRIAL
      : currentUser?.role === RolesEnum.USER;
    let secondPart = makeContentBlur ? blurredPart : remainingPart;

    return (
      <>
        <span>{firstPart}</span>
        <span className={makeContentBlur ? classes.Blurred : ""}>{secondPart}</span>
      </>
    );
  };

  const navigateToWhatIsSearchExposure = () =>
    window.open("https://www.automatoai.com/post/what-is-search-volume-exposure", "_blank")?.focus();

  const userApiCall = () => {
    UserApis.getUser()
      .then((res: any) => {
        if (res?.companyID) {
          dispatch(setCompanyAction(res?.companyID));
        }
        dispatch(setUserDispatch(res));
      })
      .catch((error: any) => console.log("error while fetching user", error));
  };

  const handlePublishOnAmazonClick = () => {
    setState({ ...state, approveLoading: true });
    const bulletPoints = [
      {
        change: `bullet_point`,
        value: completeProduct.approvedProduct?.bulletPointsByChat.map((bulletPoint: string) => ({
          value: formatBulletsPoints(bulletPoint, completeProduct?.bulletPointsFormat),
        })),
      },
    ];

    const keywordsForPayload = {
      change: "generic_keyword",
      value: completeProduct.searchTerm,
    };

    const payload = {
      productASIN: completeProduct.productASIN,
      operations: [
        {
          change: "item_name",
          value: completeProduct.approvedProduct.generatedTitle,
        },
        {
          change: "product_description",
          value: completeProduct.approvedProduct.generatedDescription,
        },
        {
          ...keywordsForPayload,
        },
        ...bulletPoints,
      ],
    };

    if (
      currentUser.role === RolesEnum.ADMIN ||
      currentUser.role === RolesEnum.INTERNAL ||
      currentUser.role === RolesEnum.OWNER
    ) {
      ProductApis.publishOnAmazonForInternalUsers(payload, completeProduct.userID)
        .then((res: any) => {
          console.log("checking response *** ", res);
          getCompleteProduct();
          if (res?.length > 0) {
            res.forEach((obj: any) =>
              toast.success(obj?.sku ? `Publishing on Amazon (SKU: ${obj.sku})` : `Publishing on amazon`)
            );
          }
          // toast.success("Publishing on amazon");
          setState({ ...state, approveLoading: false });
          cancelPublish();
        })
        .catch((err: any) => {
          console.log("checking response *** ", err);
          if (err?.response?.data?.length > 0) {
            err?.response?.data?.foreach((errObj: any) =>
              toast.error(errObj?.message === "Seller Unauthorized" ? ERR_AMAZON_AUTHORIZATION : errObj?.message)
            );
          } else {
            toast.error(errorHandler(err));
          }
          setState({ ...state, approveLoading: false });
          userApiCall();
          cancelPublish();
        });
    } else {
      ProductApis.publishOnAmazon(payload)
        .then((res: any) => {
          console.log("checking response *** ", res);
          getCompleteProduct();
          if (res?.length > 0) {
            res.forEach((obj: any) =>
              toast.success(obj?.sku ? `Publishing on Amazon (SKU: ${obj.sku})` : `Publishing on amazon`)
            );
          }
          setState({ ...state, approveLoading: false });
          cancelPublish();
        })
        .catch((err: any) => {
          console.log("checking response *** ", err);
          if (err?.response?.data?.length > 0) {
            err?.response?.data?.foreach((errObj: any) =>
              toast.error(errObj?.message === "Seller Unauthorized" ? ERR_AMAZON_AUTHORIZATION : errObj?.message)
            );
          } else {
            toast.error(errorHandler(err));
          }
          setState({ ...state, approveLoading: false });
          userApiCall();
          cancelPublish();
        });
    }
  };

  const unApproveButtonComponent = (
    <>
      {currentUser?.companyID ? (
        <>
          {showUnApprovedButtonOnDetailPageCompany(completeProduct?.status, currentUser?.role, companyRole) && (
            <MButton
              size={"small"}
              variant="outlined"
              rootClass={"ms-3"}
              onClick={handleOpenUnapprove}
              disabled={state.approveLoading}
            >
              {"Unapprove"}
            </MButton>
          )}
        </>
      ) : (
        showUnApprovedButtonOnDetailPage(currentUser?.role, completeProduct?.status) && (
          <MButton
            size={"small"}
            rootClass={"ms-3"}
            variant="outlined"
            onClick={handleOpenUnapprove}
            disabled={state.approveLoading}
          >
            {"Unapprove"}
          </MButton>
        )
      )}
    </>
  );

  const navigateToAmazon = () => {
    if (fetchAmazonASIN(completeProduct?.productASIN || "")) {
      window.open(`https://${completeProduct?.domain}/dp/${completeProduct?.productASIN}`, "_blank")?.focus();
    }
  };

  const [openPublish, setOpenPublish] = useState<boolean>(false);
  // confirmPublish cancelPublish openPublish
  const cancelPublish = () => {
    setOpenPublish(false);
  };

  const confirmPublish = () => {
    handlePublishOnAmazonClick();
  };

  const ratingComponent = (
    <div className={isDesktop ? "d-flex align-items-center gap-2 my-2" : " align-items-center gap-2"}>
      <div className={"d-flex align-items-center"}>
        {scrapeProduct?.product?.buybox_winner?.rrp?.raw ? (
          <div className={"d-flex align-items-center"}>
            {scrapeProduct?.product?.buybox_winner?.price?.raw && (
              <MTypography
                variant={"body2"}
                customClass={isDesktop ? classes.ActualPrice : classes.ActualPriceM}
              >{`${scrapeProduct?.product?.buybox_winner?.rrp?.raw}`}</MTypography>
            )}
            {scrapeProduct?.product?.buybox_winner?.price?.raw && (
              <MTypography
                variant={"body2"}
                customClass={isDesktop ? classes.Price : classes.PriceM}
              >{`${scrapeProduct?.product?.buybox_winner?.price?.raw}`}</MTypography>
            )}
          </div>
        ) : (
          <div>
            {scrapeProduct?.product?.buybox_winner?.price?.raw && (
              <MTypography
                variant={"body2"}
                customClass={classes.Price}
              >{`${scrapeProduct?.product?.buybox_winner?.price?.raw}`}</MTypography>
            )}
          </div>
        )}
      </div>
      <div className={"mt-1 d-flex align-items-center justify-content-between"}>
        <div className={"d-flex align-items-center"}>
          <MRating value={scrapeProduct?.product?.rating} isScrapeProduct={!!scrapeProduct.product} noGap={false} />
          {scrapeProduct?.product?.ratings_total && (
            <MTypography
              variant={"body2"}
              customClass={isDesktop ? classes.RatingText : classes.RatingTextM}
            >{`${scrapeProduct?.product?.ratings_total?.toLocaleString()} ratings`}</MTypography>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {state.loading ? (
        <MainLoader />
      ) : (
        <>
          {/*Web View*/}
          {isDesktop ? (
            <div>
              {/* <AppBar rightElement={<SignOutPopper />} /> */}
              <div className={classes.Container}>
                <div className={`card d-flex flex-row mb-5 ${classes.ProductMainInfoCard}`}>
                  <img
                    src={completeProduct?.imageURL || defaultImage}
                    onError={(e) => onImgErrorHandler(e, defaultImage)}
                    alt="product"
                    className={`${classes.ProductImage} cursor-pointer`}
                    onClick={navigateToAmazon}
                  />
                  <div className="d-flex flex-column ms-4 w-100">
                    <div className={"d-flex justify-content-between"}>
                      <div className={"d-flex align-items-center"}>
                        <Tooltip title="Open in Amazon" placement="top" arrow disableHoverListener={!isManualProduct}>
                          <div className="cursor-pointer" onClick={navigateToAmazon}>
                            <MTypography
                              variant={"h5"}
                              color="primary"
                              customClass={`${classes.ProductAsin} ${isManualProduct && "HoverEffectForLinks"}`}
                              children={completeProduct?.productASIN}
                            />
                          </div>
                        </Tooltip>
                        {(completeProduct?.showLiveCheck ||
                          state.showLive ||
                          checkValidObj(completeProduct?.productLive, completeProduct?.status) ||
                          completeProduct?.productLive?.isProductLive === ProductLiveStatusEnum.DEACTIVATED ||
                          showProductLiveStatus(completeProduct?.productLive, completeProduct?.status)) && (
                          <ProductStatus
                            type={
                              state.showLive
                                ? ProductLiveStatusEnum.CHECKING
                                : completeProduct?.productLive?.isProductLive
                            }
                            text={
                              state.showLive
                                ? capitalizeEachLetter(ProductLiveStatusEnum.CHECKING)
                                : completeProduct?.productLive?.isProductLive === ProductLiveStatusEnum.INACTIVE
                                ? `${productInactiveCount(completeProduct?.productLive as any)} ${
                                    productInactiveCount(completeProduct?.productLive as any) > 1
                                      ? // changing inactive to Unpulished
                                        "Unpublished Sections"
                                      : "Unpublished Section"
                                  }`
                                : capitalizeEachLetter(completeProduct?.productLive?.isProductLive?.replace("_", " "))
                            }
                            showInfo
                          />
                        )}
                        <div className={"ms-2"}>
                          <ProductStatus type={completeProduct?.status} showInfo />
                        </div>
                      </div>
                      <div className={"d-flex align-items-center"}>
                        <MTypography variant={"subtitle1"} customClass={"cursor-pointer"} color={"#80828A"}>
                          <div onClick={handleBack}>
                            <span className={"ms-1"}> {"< Back"}</span>
                          </div>
                        </MTypography>

                        {unApproveButtonComponent}
                        {currentUser?.companyID ? (
                          <>
                            {" "}
                            {showPublishOnAmazonButtonCompany(
                              currentUser?.role,
                              completeProduct?.status,
                              currentUser?.sellerPartnerAuthorized ? true : false
                            ) && (
                              <MButton
                                size={"small"}
                                rootClass={"ms-1"}
                                onClick={() => setOpenPublish(true)}
                                // onClick={handlePublishOnAmazonClick}
                                disabled={state.approveLoading}
                              >
                                Publish
                              </MButton>
                            )}{" "}
                          </>
                        ) : (
                          showPublishOnAmazonButton(
                            currentUser?.role,
                            completeProduct?.status,
                            currentUser?.sellerPartnerAuthorized ? true : false,
                            currentUser?.email
                          ) && (
                            <MButton
                              size={"small"}
                              rootClass={"ms-1"}
                              // onClick={handlePublishOnAmazonClick}
                              onClick={() => setOpenPublish(true)}
                              disabled={state.approveLoading}
                            >
                              Publish
                            </MButton>
                          )
                        )}

                        {currentUser?.companyID && completeProduct?.status !== ContentStatusEnum.PUBLISHED ? (
                          <>
                            {showApprovedButtonOnDetailPageCompany(
                              completeProduct?.status,
                              currentUser?.role,
                              companyRole
                            ) && (
                              <MButton
                                size={"small"}
                                rootClass={"ms-3"}
                                onClick={openApprove}
                                disabled={state.approveLoading}
                              >
                                {"Approve"}
                              </MButton>
                            )}
                          </>
                        ) : (
                          showApprovedButtonOnDetailPage(currentUser?.role, completeProduct?.status) &&
                          completeProduct?.status !== ContentStatusEnum.PUBLISHED && (
                            <MButton
                              size={"small"}
                              rootClass={"ms-3"}
                              onClick={openApprove}
                              disabled={state.approveLoading}
                            >
                              {"Approve"}
                            </MButton>
                          )
                        )}
                        <DetailsActionsMenu
                          state={state}
                          setState={setState}
                          handleLiveCheck={handleLiveCheck}
                          handleDelete={handleDelete}
                          handleFeedbackClick={handleFeedbackClick}
                          triggerKeywordTracking={triggerKeywordTracking}
                        />
                      </div>
                    </div>

                    {/* IMPORTANT : update this */}
                    <MTypography variant={"body1"} customClass={classes.ProductTitle}>
                      {blurString(
                        completeProduct.status !== ContentStatusEnum.APPROVED &&
                          completeProduct.status !== ContentStatusEnum.PUBLISHED
                          ? getSelectedTitleVariation(completeProduct) || "N/A"
                          : getSelectedTitleVariation(completeProduct) || "N/A"
                      )}
                    </MTypography>

                    {ratingComponent}

                    <MTypography variant={"subtitle1"} color={"#71747D"}>
                      <div className={"d-flex align-items-center"}>
                        <img src={countryDomainObject.flag} alt={"flag-icon"} className={classes.FlagIcon} />
                        <span className={`ms-1 ${classes.FlagText}`}>{countryDomainObject.name}</span>
                      </div>
                    </MTypography>

                    <div className={"d-flex mt-1"}>
                      <MTypography variant={"body1"} customClass={classes.ProductBrand} color={"#898989"}>
                        {"By"}&nbsp;
                      </MTypography>
                      <MTypography variant={"body1"} customClass={classes.ProductBrand} color={"#E6B540"}>
                        {capitalizeEachLetter(completeProduct?.brand) || "N/A"}
                      </MTypography>
                    </div>
                  </div>
                </div>
                {currentUser?.companyID ? (
                  <>
                    {showUpgradeOnProductDetailPageCompany(currentCompany?.role) &&
                      currentUser.role === RolesEnum.COMPANY_ADMIN && (
                        <div>
                          <UpgradeNowBannerDetailsPage />
                        </div>
                      )}
                  </>
                ) : (
                  <>
                    {showUpgradeOnProductDetailPage(currentUser?.role) && (
                      <div>
                        <UpgradeNowBannerDetailsPage />
                      </div>
                    )}
                  </>
                )}
                <div className={"px-3"}>
                  {completeProduct?.newToAmazon && (
                    <LinkAsin
                      asin={completeProduct?.productASIN || ""}
                      userID={completeProduct?.userID}
                      refetchProduct={setRefetchProductDetail}
                    />
                  )}
                </div>
                <div className={"mb-3"}>
                  <Grid container columnSpacing={2}>
                    <Grid item xs={8}>
                      {productDetailBsr}
                    </Grid>
                    <Grid item xs={4} pt={"34px"}>
                      <ProductEventsHistory />
                    </Grid>
                  </Grid>
                </div>
                <div className={"row"}>
                  <div className={"col-lg-6 col-md-12 col-sm-12"}>
                    <div className={"mb-2 d-flex align-items-center justify-content-between"}>
                      <MTypography variant={"subtitle1"} customClass={classes.ContentHeading}>
                        Keywords
                      </MTypography>
                      {showChangeKeywordButton(completeProduct, currentUser) && (
                        <MButton
                          variant="outlined"
                          size="small"
                          rootClass={classes.ChagneKeywords}
                          onClick={() => handleChangeKeywordsClick(completeProduct, dispatch, navigate, location, completeProduct.genericVariation)}
                        >
                          Change Keywords
                        </MButton>
                      )}
                    </div>
                    <div className={"mb-3"}>
                      <div className={"mb-2 d-flex align-items-center"}>
                        <MTypography variant={"subtitle1"} customClass={classes.KeywordsHeading}>
                          Title Keywords
                        </MTypography>
                      </div>
                      <ProductDetailsKeywords isTitle={true} />
                    </div>
                    <div className={"mb-3"}>
                      <div className={"mb-2 d-flex align-items-center"}>
                        <MTypography variant={"subtitle1"} customClass={classes.KeywordsHeading}>
                          {/* Rest of Keywords */}
                          Bullet Points + Description Keywords
                        </MTypography>
                      </div>
                      <ProductDetailsKeywords />
                    </div>
                  </div>
                  <div className={"col-lg-6 col-md-12 col-sm-12"}>
                    <div className={"mb-3 px-1"}>
                      <div className={"mb-2 d-flex align-items-center gap-2"}>
                        <MTypography variant={"subtitle1"} customClass={classes.ContentHeading}>
                          Search Exposure
                        </MTypography>
                        <MuiTooltip
                          arrow={true}
                          content={
                            <span
                              className="cursor-pointer"
                              style={{ color: "#e6b540" }}
                              onClick={navigateToWhatIsSearchExposure}
                            >
                              What is Search Exposure?
                            </span>
                          }
                          placement={"top"}
                        >
                          <InfoOutlinedIcon
                            color="primary"
                            fontSize="small"
                            className={`d-flex align-items-center justify-content-center`}
                          />
                        </MuiTooltip>
                      </div>
                      <div className={`row ${classes.RowColPadding}`}>
                        <div className={"col-3"}>
                          <InfoKpiBoxes
                            topText={"Before"}
                            middleText={beforeValue}
                            topTextColor={"#5A5A5A"}
                            topTextBackgroundColor={"#F2F2F2"}
                          />
                        </div>
                        <div className={"col-3"}>
                          <InfoKpiBoxes
                            topText={"After"}
                            middleText={afterValue}
                            topTextColor={"#38A4FF"}
                            topTextBackgroundColor={"#DDEFFF"}
                          />
                        </div>
                        {/* below here */}
                        <div className={"col-6"}>
                          <InfoKpiChangeBox
                            optimizationImpact={`${handleChangeValue(percentageChange.percentageChange)}` || "0"}
                            overAvgCompetitor={
                              `${
                                afterValue === "0" && beforeValue === "0"
                                  ? "0"
                                  : handleChangeValue(changeOverCompetitors.changeOverAvgCompetitors)
                              }` || "0"
                            }
                            optimizationIncrease={percentageChange.isIncrease}
                            overAvgCompIncrease={changeOverCompetitors.isIncrease}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={"mb-3"}>
                      <ProductDetailsSwipeableTabs />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            // Mobile View
            <div>
              <div className={"d-flex flex-column px-2"}>
                <div className={"p-2"}>
                  <div className={"d-flex justify-content-between align-items-center mt-1"}>
                    <div onClick={handleBack}>
                      <img src={backIcon} alt={"back-icon"} />
                    </div>
                    <div className="d-flex align-items-center">
                      {unApproveButtonComponent}

                      {currentUser?.companyID ? (
                        <>
                          {showPublishOnAmazonButtonCompany(
                            currentUser?.role,
                            completeProduct?.status,
                            currentUser?.sellerPartnerAuthorized ? true : false
                          ) && (
                            <MButton
                              size={"small"}
                              rootClass={"ms-1"}
                              // onClick={handlePublishOnAmazonClick}
                              onClick={() => setOpenPublish(true)}
                              disabled={state.approveLoading}
                            >
                              Publish
                            </MButton>
                          )}
                        </>
                      ) : (
                        showPublishOnAmazonButton(
                          currentUser?.role,
                          completeProduct?.status,
                          currentUser?.sellerPartnerAuthorized ? true : false,
                          currentUser?.email
                        ) && (
                          <MButton
                            size={"small"}
                            rootClass={"ms-1"}
                            // onClick={handlePublishOnAmazonClick}
                            onClick={() => setOpenPublish(true)}
                            disabled={state.approveLoading}
                          >
                            Publish
                          </MButton>
                        )
                      )}

                      {currentUser?.companyID ? (
                        <>
                          {showApprovedButtonOnDetailPageCompany(
                            completeProduct?.status,
                            currentUser?.role,
                            companyRole
                          ) && (
                            <MButton
                              size={"small"}
                              rootClass={"ms-3"}
                              onClick={openApprove}
                              disabled={state.approveLoading}
                            >
                              {"Approve"}
                            </MButton>
                          )}
                        </>
                      ) : (
                        showApprovedButtonOnDetailPage(currentUser?.role, completeProduct?.status) && (
                          <MButton
                            size={"small"}
                            rootClass={"ms-3"}
                            onClick={openApprove}
                            disabled={state.approveLoading}
                          >
                            {"Approve"}
                          </MButton>
                        )
                      )}
                      <div>
                        <DetailsActionsMenu
                          state={state}
                          setState={setState}
                          handleLiveCheck={handleLiveCheck}
                          handleDelete={handleDelete}
                          handleFeedbackClick={handleFeedbackClick}
                          triggerKeywordTracking={triggerKeywordTracking}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={isDesktop ? "d-flex align-items-center gap-3 mt-4 mb-2" : "d-flex gap-3 mt-4 mb-2"}>
                    <img
                      src={completeProduct?.imageURL || defaultImage}
                      onError={(e) => onImgErrorHandler(e, defaultImage)}
                      alt="product"
                      className={`${classes.ProductImage}`}
                      onClick={navigateToAmazon}
                    />
                    <div>
                      <div className={"d-flex align-items-center"} onClick={navigateToAmazon}>
                        <MTypography
                          variant={"h5"}
                          customClass={classes.ProductAsin}
                          color="primary"
                          children={completeProduct?.productASIN}
                        />
                      </div>
                      {ratingComponent}
                      <div className={"d-flex mt-1"}>
                        <MTypography variant={"subtitle1"} color={"#71747D"}>
                          <div className={"d-flex align-items-center"}>
                            <img src={countryDomainObject.flag} alt={"flag-icon"} className={classes.FlagIcon} />
                            <span className={`ms-1 ${classes.FlagTextM}`}>{countryDomainObject.name}</span>
                          </div>
                        </MTypography>
                      </div>
                      <div className={"d-flex mt-3"}>
                        <ProductStatus type={completeProduct?.status} showInfo />
                        {(completeProduct?.showLiveCheck || state.showLive) && (
                          <div className={"ms-2"}>
                            <ProductStatus
                              type={
                                state.showLive
                                  ? ProductLiveStatusEnum.CHECKING
                                  : completeProduct?.productLive?.isProductLive
                              }
                              text={
                                state.showLive
                                  ? capitalizeEachLetter(ProductLiveStatusEnum.CHECKING)
                                  : completeProduct?.productLive?.isProductLive === ProductLiveStatusEnum.INACTIVE
                                  ? `${productInactiveCount(completeProduct?.productLive as any)} ${
                                      productInactiveCount(completeProduct?.productLive as any) > 1
                                        ? "Unpublished Sections"
                                        : "Unpublished Section"
                                    }`
                                  : capitalizeEachLetter(completeProduct?.productLive?.isProductLive?.replace("_", " "))
                              }
                              showInfo
                            />
                          </div>
                        )}
                      </div>
                      <div className={"d-flex mt-2"}>
                        <MTypography variant={"body1"} customClass={classes.ProductBrandM} color={"#898989"}>
                          {"By"}&nbsp;
                        </MTypography>
                        <MTypography variant={"body1"} customClass={classes.ProductBrandM} color={"#E6B540"}>
                          {completeProduct?.brand || "N/A"}
                        </MTypography>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"px-3"}>
                  {completeProduct?.newToAmazon && (
                    <LinkAsin
                      asin={completeProduct?.productASIN || ""}
                      userID={completeProduct?.userID}
                      refetchProduct={setRefetchProductDetail}
                    />
                  )}
                </div>
                {currentUser?.companyID ? (
                  <>
                    {showUpgradeOnProductDetailPageCompany(currentCompany?.role) &&
                      currentUser.role === RolesEnum.COMPANY_ADMIN && (
                        <div>
                          <UpgradeNowBannerDetailsPage />
                        </div>
                      )}
                  </>
                ) : (
                  <>
                    {showUpgradeOnProductDetailPage(currentUser?.role) && (
                      <div>
                        <UpgradeNowBannerDetailsPage />
                      </div>
                    )}
                  </>
                )}
                <div className={"mt-2 px-2"}>
                  <div className={"mb-2 d-flex align-items-center gap-2"}>
                    <MTypography variant={"subtitle1"} customClass={classes.ContentHeading}>
                      Search Exposure
                    </MTypography>
                    <MuiTooltip
                      arrow={true}
                      content={
                        <span
                          className="cursor-pointer"
                          style={{ color: "#e6b540" }}
                          onClick={navigateToWhatIsSearchExposure}
                        >
                          What is Search Exposure?
                        </span>
                      }
                      placement={"top"}
                    >
                      <InfoOutlinedIcon
                        color="primary"
                        fontSize="small"
                        className={`d-flex align-items-center justify-content-center`}
                      />
                    </MuiTooltip>
                  </div>
                  <div className={`row mb-4 ${classes.SearchVolumeExposureRowM} RowColPadding`}>
                    <div className={"col-6"}>
                      <InfoKpiBoxes
                        topText={"Before"}
                        middleText={beforeValue}
                        topTextColor={"#5A5A5A"}
                        topTextBackgroundColor={"#F2F2F2"}
                      />
                    </div>
                    <div className={"col-6"}>
                      <InfoKpiBoxes
                        topText={"After"}
                        middleText={afterValue}
                        topTextColor={"#38A4FF"}
                        topTextBackgroundColor={"#DDEFFF"}
                      />
                    </div>
                    {/* below here */}
                    <div className={"col-12"}>
                      <InfoKpiChangeBox
                        optimizationImpact={`${handleChangeValue(percentageChange.percentageChange)}` || "0"}
                        overAvgCompetitor={
                          `${
                            afterValue === "0" && beforeValue === "0"
                              ? "0"
                              : handleChangeValue(changeOverCompetitors.changeOverAvgCompetitors)
                          }` || "0"
                        }
                        optimizationIncrease={percentageChange.isIncrease}
                        overAvgCompIncrease={changeOverCompetitors.isIncrease}
                      />
                    </div>
                  </div>
                  <div className={"mb-4 d-flex flex-column gap-4 w-100"}>
                    <div>
                      <ProductEventsHistory />
                    </div>
                    <div>{productDetailBsr}</div>
                  </div>
                  <div className={`row mb-4 ${classes.SearchVolumeExposureRowM} `}>
                    <div className={"col-6"}>
                      <div className={classes.KeywordCompetitorCard} onClick={openKeywordsBottomSheet}>
                        <img src={keywordIcon} alt="keyword-icon" />
                        <MTypography variant={"h5"} customClass={classes.KeywordCompetitorCardTitle}>
                          Keywords
                        </MTypography>
                        <MTypography variant={"subtitle1"} customClass={classes.KeywordCompetitorCardSubTitle}>
                          {/* 7 out of 10 keywords selected */}
                          {`Here is the list of ${completeProduct?.selectedHelium10Keywords?.length} selected keywords.`}
                        </MTypography>
                      </div>
                    </div>
                    <div className={"col-6"}>
                      {/*<div className={classes.KeywordCompetitorCard} onClick={openCompetitorsBottomSheet}>*/}
                      {/*  <img src={competitorIcon} alt="competitor-icon" />*/}
                      {/*  <MTypography variant={"h5"} customClass={classes.KeywordCompetitorCardTitle}>*/}
                      {/*    Competitors*/}
                      {/*  </MTypography>*/}
                      {/*  <MTypography variant={"subtitle1"} customClass={classes.KeywordCompetitorCardSubTitle}>*/}
                      {/*    {`Here is the list of ${completeProduct?.generatedContentCompetitors?.length} competitors`}*/}
                      {/*  </MTypography>*/}
                      {/*</div>*/}
                    </div>
                    <MBottomSheet open={state.openKeywordsBottomSheet}>
                      <div className={"d-flex justify-content-between align-items-center mb-4"}>
                        <MTypography variant={"subtitle1"} customClass={classes.BottomSheetTitle}>
                          {"Keywords"}
                        </MTypography>
                        <CloseIcon onClick={closeKeywordBottomSheet} className={"mx-2 d-flex align-items-center"} />
                      </div>
                      <div className={"mt-2"}>
                        <MTypography variant={"subtitle1"} customClass={classes.BottomSheetSubTitle}>
                          {"Title Keywords"}
                        </MTypography>
                        <ProductDetailsKeywords isTitle />
                      </div>
                      <div className={"mt-2"}>
                        <MTypography variant={"subtitle1"} customClass={classes.BottomSheetSubTitle}>
                          {/* {"Rest of Keywords"} */}
                          Bullet Points + Description Keywords
                        </MTypography>
                        <ProductDetailsKeywords />
                      </div>
                      {showChangeKeywordButton(completeProduct, currentUser) && (
                        <div className="position-relative mt-5">
                          <div style={{ position: "fixed", bottom: 0, width: "100%" }}>
                            <MButton
                              variant="contained"
                              fullWidth
                              onClick={() => handleChangeKeywordsClick(completeProduct, dispatch, navigate, location, completeProduct.genericVariation)}
                            >
                              Change Keywords
                            </MButton>
                          </div>
                        </div>
                      )}
                    </MBottomSheet>
                    <MBottomSheet open={state.openCompetitorsBottomSheet}>
                      <div className={"d-flex justify-content-between align-items-center mb-4 "}>
                        <MTypography variant={"subtitle1"} customClass={classes.BottomSheetTitle}>
                          {"Competitors"}
                        </MTypography>
                        <CloseIcon onClick={closeKeywordBottomSheet} className={"mx-2 d-flex align-items-center"} />
                      </div>
                      <div className={"mt-2"}>
                        <ProductDetailsCompetitors />
                      </div>
                    </MBottomSheet>
                  </div>
                  <div className={"mb-3"}>
                    <ProductDetailsSwipeableTabs />
                  </div>
                </div>
              </div>
            </div>
          )}
          <FeedbackDialogue
            open={state.feedbackBox}
            onClose={() => {
              setState({ ...state, feedbackBox: false });
            }}
            feedbacks={completeProduct?.feedbacks?.length ? completeProduct?.feedbacks : []}
          />

          <ActionBox
            handleAction={confirmDelete}
            handleBack={cancelDelete}
            open={state.openDeleteConfirm}
            actionText={"Delete"}
            message={"Do you want to delete your product?"}
            title={"Delete Generated Product"}
            backText={"Cancel"}
          />

          <ActionBox
            handleAction={confirmApprove}
            handleBack={cancelApprove}
            open={state.openConfirmApprove}
            actionText={"Approve"}
            message={
              userRole.isAdminOrEditorOrInternalOrOwner(currentUser?.role)
                ? "Are you sure you want to mark this as approved? You need to get customer approval in order to do this."
                : "Are you sure you want to mark this as approved?"
            }
            title={"Confirm Approve Product"}
            backText={"Cancel"}
            isLoading={state.approveLoading}
          />

          <ActionBox
            handleAction={confirmApprove}
            handleBack={cancelUnapprove}
            open={openUnapprove}
            actionText={"Unapprove"}
            message={"Are you sure you want to mark this as unapproved?"}
            title={"Confirm Action"}
            backText={"Cancel"}
            isLoading={state.approveLoading}
          />

          <ActionBox
            handleAction={confirmPublish}
            handleBack={cancelPublish}
            open={openPublish}
            actionText={"Publish"}
            message={"Are you sure you want to publish this product on amazon?"}
            title={"Confirm Publish"}
            backText={"Cancel"}
            isLoading={state.approveLoading}
          />

          <InCompleteProductError refetch={setRefetchProductDetail} open={showIncompleteBullet(completeProduct)} />
        </>
      )}
    </div>
  );
};

export default ProductDetails;

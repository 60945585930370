import React from "react";
import classes from "./index.module.css";
import MTypography from "../MTypography";
import { useDeviceType } from "../../../hooks/useDeviceType";
import { Box, Divider } from "@mui/material";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";

interface InfoKpiChangeBoxProps {
  optimizationImpact: string;
  overAvgCompetitor: string;
  optimizationIncrease?: boolean;
  overAvgCompIncrease?: boolean;
}

const InfoKpiChangeBox: React.FC<InfoKpiChangeBoxProps> = ({
  optimizationImpact,
  overAvgCompetitor,
  optimizationIncrease = true,
  overAvgCompIncrease = true,
}) => {
  const { isDesktop } = useDeviceType();
  return (
    <>
      <div className={isDesktop ? classes.KpiCard : classes.KpiCardM}>
        <div className={classes.KpiCardTopDiv} style={{ backgroundColor: "#BFFFE8" }}>
          <MTypography
            variant={"subtitle1"}
            customClass={`text-center ${
              isDesktop ? `${classes.KpiCardTopBottomText} fw-semibold` : classes.KpiCardTopBottomTextM
            }`}
            color={"#0CC683"}
          >
            {optimizationImpact.includes("%") && overAvgCompetitor.includes("%") ? "%" : ""} Change
          </MTypography>
        </div>
        <Box display={"flex"}>
          {/* for optimizationImpact */}
          <Box width={"50%"}>
            <div>
              <Box
                component={"div"}
                className={`${classes.ChangeTopTitle}`}
                color="#2E2E2E"
                fontSize={{ lg: "8px !important", xl: "10px !important" }}
              >
                Optimization Impact
              </Box>
            </div>
            <div className={`d-flex mt-1 justify-content-center align-items-center`}>
              <MTypography
                title={optimizationImpact}
                variant={"h1"}
                customClass={isDesktop ? classes.KpiCardMiddleText : classes.KpiCardMiddleTextM}
              >
                {optimizationImpact}
              </MTypography>
            </div>
            <div>
              <MTypography
                variant={"subtitle1"}
                customClass={isDesktop ? classes.KpiCardTopBottomText : classes.KpiCardTopBottomTextM}
                color={optimizationIncrease || overAvgCompetitor === "0" ? "#0CC683" : "#F93939"}
              >
                {optimizationIncrease || overAvgCompetitor === "0" ? (
                  <div className="d-flex justify-content-center align-items-center">
                    Higher
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "15px" }} />
                  </div>
                ) : (
                  <div className="d-flex justify-content-center align-items-center">
                    Lower
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "15px" }} />
                  </div>
                )}
              </MTypography>
            </div>
          </Box>
          <Divider orientation="vertical" flexItem sx={{ opacity: 1, mt: "22px" }} />
          <Box width={"50%"}>
            {/* for overAvgCompetitor */}
            <div>
              <Box
                component={"div"}
                className={`${classes.ChangeTopTitle} fw-semibold`}
                fontFamily={"inherit"}
                color="#2E2E2E"
                fontSize={{ lg: "8px !important", xl: "10px !important" }}
              >
                Over AVG Competitor
              </Box>
            </div>
            <div className={`d-flex mt-1 justify-content-center align-items-center`}>
              <MTypography
                title={optimizationImpact}
                variant={"h1"}
                customClass={isDesktop ? classes.KpiCardMiddleText : classes.KpiCardMiddleTextM}
              >
                {overAvgCompetitor}
              </MTypography>
            </div>
            <div>
              <MTypography
                variant={"subtitle1"}
                customClass={isDesktop ? classes.KpiCardTopBottomText : classes.KpiCardTopBottomTextM}
                color={overAvgCompIncrease || overAvgCompetitor === "0" ? "#0CC683" : "#F93939"}
              >
                {overAvgCompIncrease || overAvgCompetitor === "0" ? (
                  <div className="d-flex justify-content-center align-items-center">
                    Higher
                    <ArrowUpwardRoundedIcon fontSize="inherit" sx={{ fontSize: "15px" }} />
                  </div>
                ) : (
                  <div className="d-flex justify-content-center align-items-center">
                    Lower
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "15px" }} />
                  </div>
                )}
              </MTypography>
            </div>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default InfoKpiChangeBox;

import React, { useState, useMemo, useEffect, useRef } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";

import Columns from "../DragColumn";
import MTypography from "../../../Atoms/MTypography";
import MTextField from "../../../Atoms/MTextField";
import Space from "../../../Atoms/Space";
import MTabs from "../../../Atoms/MTabs";
import MButton from "../../../Atoms/MButton";
import KeywordsPageDialoge from "../../../mui/dialogebox/keywordsPageDialoge";
import CustomKeywordForm from "../CustomKeywordForm";

import { useDeviceType } from "../../../../hooks/useDeviceType";
import { PriortiseKeywordsSelector, SelectedKeywordsSelector } from "../../../../store/product/product.selector";
import useMTab from "../../../Atoms/MTabs/useMTab";
import {
  suggestionsTabs,
  suggestionsTabsValue,
  DragAndDropsPageInputs,
  seoKeywordSearch,
  initialColumnsPriortiseKeywords,
  shuffleElementsInArray,
  minTitleKeywords,
  warningMessageTitle,
  maxTotalKeywordsAllowed,
  maxTotalKeywordWarning,
  filterOptions,
  sortResultByFilter,
  sortFilters,
  warningMaxTitleSlots,
  maxSelectedKeywords,
  moveKeywords,
  moveValidation,
} from "./config";
import { caseInsensitiveSearch } from "../../../../utils/helpers/strings";

import VerticalLineIcon from "../../../../assets/png/vertical-line-icon.png";

import classes from "./index.module.css";
import { insertElementInArray, removeElementInArray } from "../../../../utils/helpers/general-helpers";
import { toast } from "react-toastify";
import { CatalogEnum, ParamsEnum, RolesEnum, TabsEnum } from "../../../../utils/constants/enums";
import { errorHandler } from "../../../../utils/helpers/apis";
import { useDialog } from "../../../Atoms/Dialog/useDialog";
import {
  getPhrase,
  resetSelectedPrioritizeKeywordsDispatch,
  selectAndGenerateKeywordsAction,
} from "../../../../store/product/product.actions";
import { URL_LANDING_PAGE, URL_PRODUCT_CATALOG } from "../../../../routes/routes-path";

// import useScreenWidth from "../../../../hooks/useScreenWidth";
import { BottomNavigation, Box, LinearProgress, Paper, SelectChangeEvent } from "@mui/material";
import MSelect from "../../../Atoms/MSelect";
import { generateProductUsingOpenAIByKeywordsTitleBulletPointsAndDescription } from "../../../../apis/product";
import { KeywordsApis } from "../../../../apis/keywords";
import { DbUserSelector } from "../../../../store/user/user.selector";
import {
  checkIsCompanyReachedHeliumOrContentRegenLimit,
  checkIsProductOwnerReachedHisLimit,
} from "../../../../pages/SavedProducts/config";
import ToastError from "../../../Atoms/ToastError";
import { UnlockGreaterPossibilities, UserReachedRegenLimit } from "../../../../utils/constants/messages/errors";
import { getKeywordType } from "../../../../pages/Product/keywords/config";
import MuiTooltip from "../../../Atoms/MuiTooltip";
interface Props {
  isManual: boolean;
  closeManual: () => void;
}
const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
};
const DragAndDrop: React.FC<Props> = (props) => {
  const { isManual, closeManual } = props;
  const currentUser = useSelector(DbUserSelector);
  const { handleTabChange, activeTab } = useMTab(suggestionsTabs);
  const priortiseKeywords = useSelector(PriortiseKeywordsSelector);
  const selectedKeywords = useSelector(SelectedKeywordsSelector);
  const { isDesktop } = useDeviceType();
  const location = useLocation();

  const { isOpen, onOpen, onClose } = useDialog();
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  // const changeKeywordOwnerId = useSelector(ChangeKeywordProductOwnerId)
  const changeKeywordOwnerId = localStorage.getItem("productOwnerId")
    ? localStorage.getItem("productOwnerId")
    : undefined;

  const isGenericVariation = JSON.parse(localStorage.getItem("isGenericVariation") ?? "false");

  // const { screenWidth } = useScreenWidth();

  const [inputs, setInputs] = useState<DragAndDropsPageInputs>({
    search: "",
    customKeyword: "",
  });

  const [columns, setColumns] = useState<any>(initialColumnsPriortiseKeywords);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sellingKeywords, setSellingKeywords] = useState<string[]>([]);
  const [filter, setFilter] = useState<string>(sortFilters.currentRank);
  const [selectedItem, setSelectedItem] = useState<any>(initialColumnsPriortiseKeywords);

  const isActiveAddBtns = selectedItem.suggestions.list.length > 0 || selectedItem.selling.list.length > 0;

  const isActiveMoveBack = selectedItem.restKeywords.list.length > 0 || selectedItem.selectedKeywords.list.length > 0;
  let sliderRef: any = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  const handleSelected = ({ id, item, isSelected = false }: any) => {
    if (isSelected) {
      if (item.hasOwnProperty("phrase")) {
        setSelectedItem({
          ...selectedItem,
          [id]: {
            id,
            list: selectedItem[id].list.filter((i: any) => i.phrase !== item.phrase),
          },
        });
      } else {
        setSelectedItem({
          ...selectedItem,
          [id]: {
            id,
            list: selectedItem[id].list.filter((i: any) => i !== item),
          },
        });
      }
    } else {
      setSelectedItem({
        ...selectedItem,
        [id]: {
          id,
          list: [...selectedItem[id].list, item],
        },
      });
    }
  };

  const setSortFilter = (filter: string): void => {
    const suggestionsList = sortResultByFilter({ filter, array: columns.suggestions.list });
    setColumns({
      ...columns,
      suggestions: {
        id: "suggestions",
        list: suggestionsList,
      },
    });
    setFilter(filter);
  };
  const moveTo = (moveToKeyword: string): any => {
    if (!moveValidation({ columns, selectedItem, moveToKeyword })) {
      return false;
    }
    setColumns({ ...moveKeywords({ columns, selectedItem, moveToKeyword }) });
    setSelectedItem(initialColumnsPriortiseKeywords);
    if (moveToKeyword === "moveBack") {
      previous();
    } else {
      next();
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    // it is implement to preselect the title and rest of keywords
    if (params.asin !== undefined) {
      setIsLoading(true);
      const user_id = changeKeywordOwnerId ? changeKeywordOwnerId : currentUser?._id;
      KeywordsApis.getKeywords({ productAsin: params?.asin, userID: user_id })
        .then((res) => {
          setColumns({
            ...priortiseKeywords,
            suggestions: {
              id: "suggestions",
              list: sortResultByFilter({ filter, array: res.selectedHelium10Keywords }),
            },
            selling: {
              id: "selling",
              list: res.sellingKeywords,
            },
            selectedKeywords: {
              id: "selectedKeywords",
              list: res.titleKeywords,
            },
            restKeywords: {
              id: "restKeywords",
              list: res.otherKeywords,
            },
          });
          setIsLoading(false);
        })
        .catch(() => {
          setColumns(priortiseKeywords);
          setIsLoading(false);
        });
    }
  }, []);

  /* eslint-disable react-hooks/exhaustive-deps */
  const filteredSellingKeywords = useMemo(() => {
    try {
      return { id: "selling", list: caseInsensitiveSearch(columns.selling.list, inputs.search) };
    } catch (e) {
      return columns.selling;
    }
  }, [inputs.search, columns.selling.list]);
  /* eslint-disable react-hooks/exhaustive-deps */
  const filteredSeoKeywords = useMemo(() => {
    try {
      return { id: "suggestions", list: seoKeywordSearch(columns.suggestions.list, inputs.search, filter) };
    } catch (e) {
      return columns.selling;
    }
  }, [inputs.search, columns.suggestions.list]);

  const handleFilterChange = (e: SelectChangeEvent) => {
    try {
      const filter: string = e.target.value;
      setSortFilter(filter);
    } catch (e) {
      console.log("error handleFilterChange", e);
    }
  };

  const onDragEnd = ({ source, destination }: DropResult) => {
    // Make sure we have a valid destination
    if (destination === undefined || destination === null) return null;

    // Make sure we're actually moving the item
    if (source.droppableId === destination.droppableId && destination.index === source.index) return null;

    const sourceId: string = source.droppableId;
    const destinationId: string = destination.droppableId;

    let sourceIndex: number = source.index;
    let destinationIndex: number = destination.index;

    //fix for search keywords
    if (inputs.search.length > 0 && activeTab === suggestionsTabsValue.seo && sourceId === "suggestions") {
      const activeKeyword = filteredSeoKeywords.list[source.index];
      columns[sourceId].list.forEach((item: any, index: number) => {
        if (item.phrase === activeKeyword.phrase) {
          sourceIndex = index;
        }
      });
    } else if (inputs.search.length > 0 && activeTab === suggestionsTabsValue.selling && sourceId === "selling") {
      const activeKeyword = filteredSeoKeywords.list[source.index];
      columns[sourceId].list.forEach((item: any, index: number) => {
        if (item === activeKeyword) {
          sourceIndex = index;
        }
      });
    }

    //Make sure we're checking for shuffling
    if (sourceId === destinationId) {
      const shuffledArray = shuffleElementsInArray({
        array: [...columns[destinationId].list],
        index1: sourceIndex,
        index2: destinationIndex,
      });
      setColumns({
        ...columns,
        [destinationId]: {
          ...columns[destinationId],
          list: shuffledArray,
        },
      });
      return null;
    }

    if (destinationId === "selectedKeywords" && columns.selectedKeywords.list.length === maxSelectedKeywords) {
      toast.warning(warningMaxTitleSlots);
      return null;
    }

    if (
      !(
        (sourceId === "restKeywords" || sourceId === "selectedKeywords") &&
        (destinationId === "restKeywords" || destinationId === "selectedKeywords")
      ) &&
      (destinationId === "selectedKeywords" || destinationId === "restKeywords") &&
      columns.selectedKeywords.list.length + columns.restKeywords.list.length >= maxTotalKeywordsAllowed
    ) {
      toast.warning(maxTotalKeywordWarning);
      return null;
    }

    const extractedElement = columns[sourceId].list[sourceIndex];

    const removedElementList = removeElementInArray([...columns[sourceId].list], sourceIndex);

    const updatedElementList = insertElementInArray(
      [...columns[destinationId].list],
      destinationIndex,
      extractedElement
    );
    const data: any = {
      ...columns,
      [sourceId]: {
        ...columns[sourceId],
        list: [...removedElementList],
      },
      [destinationId]: {
        ...columns[destinationId],
        list: [...updatedElementList],
      },
    };
    setColumns({ ...data });
    return null;
  };

  const onDragStart = (e: any) => {
    // const item = e.draggableId.split("-")[0];
    const index = e.source.index;
    const dropAbleId = e.source.droppableId;
    const selectedKeyword = columns[getKeywordType(e.draggableId || "")].list[index];
    if (dropAbleId === "selling") {
    } else if (e.draggableId.includes("object")) {
      if (
        activeTab !== suggestionsTabs[1] &&
        selectedKeyword &&
        selectedKeyword.hasOwnProperty("sellingKeyword") &&
        selectedKeyword.sellingKeyword
      ) {
        toast.warning(`Selling keywords section active now you can drag ${e.draggableId.split("-")[0]} into it.`);
        handleTabChange(suggestionsTabs[1]);
      } else if (
        activeTab !== suggestionsTabs[0] &&
        selectedKeyword &&
        !selectedKeyword.hasOwnProperty("sellingKeyword")
      ) {
        toast.warning(`Seo keywords section active now you can drag ${e.draggableId.split("-")[0]} into it.`);
        handleTabChange(suggestionsTabs[0]);
      }
    } else {
      if (activeTab !== suggestionsTabs[1])
        toast.warning(`Selling keywords section active now you can drag ${e.draggableId.split("-")[0]} into it.`);
      handleTabChange(suggestionsTabs[1]);
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const addSellingKeyword = (): boolean | void => {
    if (inputs.customKeyword) {
      const checkKeywordExist = sellingKeywords.find((word) => word === inputs.customKeyword);
      if (checkKeywordExist) {
        toast.warning("Keyword already exist");
        return false;
      }

      setSellingKeywords([...sellingKeywords, inputs.customKeyword]);
      setColumns({
        ...columns,
        selling: {
          ...columns.selling,
          list: [...columns.selling.list, inputs.customKeyword],
        },
      });
      setInputs({
        ...inputs,
        customKeyword: "",
      });
    }
  };

  const showResultHandler = (): null | void => {
    if (columns.selectedKeywords.list.length + columns.restKeywords.list.length > maxTotalKeywordsAllowed) {
      toast.warning(maxTotalKeywordWarning);
      return null;
    }
    if (columns.selectedKeywords.list.length < minTitleKeywords) {
      toast.warning(warningMessageTitle);
      return null;
    }
    onOpen();
    setIsLoading(true);
    dispatch(
      selectAndGenerateKeywordsAction({
        productASIN: params[ParamsEnum.ASIN] || "",
        keywords: selectedKeywords,
        titleKeywords: columns.selectedKeywords.list,
        otherKeywords: columns.restKeywords.list,
        selectedSellingKeywords: columns.selling.list,
        remainingSeoKeywords: columns.suggestions.list,
        userID: changeKeywordOwnerId,
        isRerun: false,
      })
    )
      .then(() => {
        setIsLoading(false);
        dispatch(resetSelectedPrioritizeKeywordsDispatch());
      })
      .catch((e: any) => {
        toast.error(errorHandler(e));
        setIsLoading(false);
      });
  };

  const handleShowResultHandler = async () => {
    if (changeKeywordOwnerId) {
      const isLimitReached = currentUser?.companyID
        ? await checkIsCompanyReachedHeliumOrContentRegenLimit(currentUser?.companyID)
        : await checkIsProductOwnerReachedHisLimit(changeKeywordOwnerId);
      if (isLimitReached) {
        if (currentUser?.companyID) {
          if (currentUser?.role === RolesEnum.COMPANY_ADMIN) {
            toast.warn(<ToastError error={UnlockGreaterPossibilities} />);
          } else {
            toast.warn(<ToastError error={UnlockGreaterPossibilities} showPricingPlan={false} />);
          }
          setTimeout(() => {
            handleCancel();
          }, 2000);
        } else {
          if (changeKeywordOwnerId === currentUser?._id) {
            toast.warn(<ToastError error={UnlockGreaterPossibilities} />);
            // onOpen();
          } else if (changeKeywordOwnerId !== currentUser?._id) {
            toast.error(UserReachedRegenLimit);
          } else {
          }
          setTimeout(() => {
            handleCancel();
          }, 2000);
        }
      } else {
        showResultHandler();
      }
    } else {
      // while creating new product
      showResultHandler();
    }
  };

  const navigateToHome = () => {
    navigate(`${URL_LANDING_PAGE}/${TabsEnum.SAVED}`, { replace: true });
  };

  const skipNow = () => {
    onOpen();
    setIsLoading(true);
    generateProductUsingOpenAIByKeywordsTitleBulletPointsAndDescription({
      productASIN: params[ParamsEnum.ASIN] || "",
      titleKeywords: [],
      otherKeywords: [],
      searchTermKeywords: selectedKeywords.map((keyword: any) => getPhrase(keyword)),
      userID: changeKeywordOwnerId,
      isRerun: false,
    })
      .then(() => {
        // onClose();
        setIsLoading(false);
        dispatch(resetSelectedPrioritizeKeywordsDispatch());
        // navigateToHome();
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  const handleCancel = () => {
    navigate(`${URL_PRODUCT_CATALOG}/${CatalogEnum.DETAIL_PAGE}/${params?.asin}/${changeKeywordOwnerId}`, {
      state: { isChildComponent: location.state?.originPage },
    });
  };

  const columnsComponents = (columns: any) => {
    return {
      suggestion: (
        <div className={isDesktop ? `p-x d-flex w-100 flex-column` : classes.MobileContainer}>
          <div className={"d-flex flex-row justify-content-between align-items-center w-100"}>
            <MTypography variant={"h6"} customClass={""}>
              Suggestions
            </MTypography>
            <MTextField
              onChange={handleChange}
              name={"search"}
              placeholder={"Search"}
              margin={"dense"}
              icon={"search"}
              value={inputs.search}
            />
          </div>
          <Space top={"16px"} />

          <div
            className={
              isDesktop
                ? "d-flex justify-content-between align-items-center"
                : "d-flex flex-column justify-content-between "
            }
          >
            <MTabs
              handleChange={(tab: string) => {
                if (!isActiveAddBtns) handleTabChange(tab);
              }}
              value={activeTab}
              tabs={suggestionsTabs}
            />
          </div>
          {isDesktop && <hr className={classes.Divider} />}

          {activeTab === suggestionsTabsValue.seo && (
            <div>
              <div className={"d-flex align-items-center justify-content-end w-100 px-2"}>
                <MTypography variant={"caption"} customClass={"h8-normal"}>
                  Sort By:
                </MTypography>
                <div className={"w-50"}>
                  <MSelect
                    margin={"dense"}
                    handleChange={handleFilterChange}
                    value={filter}
                    options={filterOptions}
                    showFullWidth
                  />
                </div>
              </div>
              <div className={isDesktop ? classes.SeoColumn : classes.SeoColumnMobile}>
                <Columns
                  handleSelected={handleSelected}
                  selectedItem={selectedItem}
                  col={inputs.search.length > 0 ? filteredSeoKeywords : columns.suggestions}
                  key={columns.suggestions.id}
                />
              </div>
            </div>
          )}
          {activeTab === suggestionsTabsValue.selling && (
            <div className={"mt-4"}>
              <div className={isDesktop ? "d-flex gap-2 mb-2" : "d-flex flex-column gap-2 mb-2"}>
                <MTextField
                  onChange={handleChange}
                  name={"customKeyword"}
                  placeholder={"Custom Keyword here ..."}
                  margin={"dense"}
                  value={inputs.customKeyword}
                  rootClass={"flex-grow-1"}
                />
                <div className={classes.AddButton}>
                  <MButton rootClass={"w-100"} onClick={addSellingKeyword} size={"small"}>
                    Add
                  </MButton>
                </div>
              </div>
              <MTypography variant={"subtitle2"} customClass={classes.KeywordText}>
                Keywords
              </MTypography>
              <div className={classes.SellingColumn}>
                <Columns
                  handleSelected={handleSelected}
                  selectedItem={selectedItem}
                  col={inputs.search.length > 0 ? filteredSellingKeywords : columns.selling}
                  key={columns.selling.id}
                />
              </div>
            </div>
          )}
        </div>
      ),
      title: (
        <div className={`  ${classes.TitleKeywordContainer}`}>
          <div className={"d-flex gap-2 align-items-center"}>
            <MTypography variant={"h6"} customClass={""}>
              Selected Keywords
            </MTypography>
            <MTypography variant={"h6"} customClass={classes.TotalNumber}>
              {columns.selectedKeywords.list.length + columns.restKeywords.list.length}/{maxTotalKeywordsAllowed}
            </MTypography>
          </div>
          <Space top={"10px"} />
          <div className={"d-flex justify-content-between align-items-center"}>
            <MTypography variant={"h6"} customClass={"h8"}>
              Title Keywords
            </MTypography>
            <MTypography customClass={classes.CaptionText} variant={"caption"}>
              {isDesktop ? "Drag and drop at least 2 title keywords here" : "Add at least 2 title keywords here"}
            </MTypography>
          </div>
          <Columns
            handleSelected={handleSelected}
            selectedItem={selectedItem}
            isSelected
            col={columns.selectedKeywords}
            key={columns.selectedKeywords.id}
          />
        </div>
      ),
      rest: (
        <div className={`  ${classes.RestOfKeywordsContainer}`}>
          <MTypography variant={"h6"} customClass={"h8"}>
            {/* Rest of Keywords */}
            Bullet Points + Description Keywords
          </MTypography>
          <Columns
            handleSelected={handleSelected}
            selectedItem={selectedItem}
            isSelected
            col={columns.restKeywords}
            key={columns.restKeywords.id}
          />
        </div>
      ),
    };
  };

  return (
    <Box component={"div"} className={isDesktop ? `flex w-100` : ""} sx={{ px: { xs: 0, lg: "24px", xl: "80px" } }}>
      <KeywordsPageDialoge
        onClick={() => navigateToHome}
        open={isOpen}
        onClose={() => {
          onClose();
          navigateToHome();
        }}
      />

      {isManual ? (
        <CustomKeywordForm
          isLoading={isLoading}
          onOpen={onOpen}
          onClose={onClose}
          setIsLoading={(status: boolean) => setIsLoading(status)}
          closeManual={closeManual}
        />
      ) : (
        <div>
          {!isDesktop && (
            <div className="slider-container">
              <Slider
                {...settings}
                ref={(slider: any) => {
                  sliderRef = slider;
                }}
              >
                <div>
                  <div style={{ minWidth: `${100}%` }}>{columnsComponents(columns).suggestion}</div>
                </div>
                <div style={{ marginLeft: "1px" }}>
                  <div style={{ minWidth: `${100}%` }}>{columnsComponents(columns).title}</div>
                  <div style={{ minWidth: `${100}%` }}>{columnsComponents(columns).rest}</div>
                </div>
              </Slider>
            </div>
          )}

          {isDesktop && (
            <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={onDragStart}>
              <div style={{ height: "10px" }}>{isLoading && <LinearProgress />}</div>
              {isDesktop && (
                <div className={isDesktop ? "d-flex flex-row gap-4" : classes.MobileContainer}>
                  <div className={"w-50"}>{columnsComponents(columns).suggestion}</div>

                  {isDesktop && (
                    <div className={classes.VerticalLine}>
                      <img src={VerticalLineIcon} className={classes.VerticalLineIcon} alt="" />
                    </div>
                  )}

                  <div className={"d-flex flex-column gap-4 w-50"}>
                    {columnsComponents(columns).title}
                    {columnsComponents(columns).rest}
                  </div>
                </div>
              )}
            </DragDropContext>
          )}

          {!isDesktop && <Space top={"140px"} />}

          {isDesktop ? (
            <div className={"d-flex justify-content-end my-4 gap-2"}>
              {changeKeywordOwnerId && (
                <MButton onClick={handleCancel} width={"medium"} size={"small"} variant={"text"}>
                  Cancel
                </MButton>
              )}
              {!changeKeywordOwnerId && (
                <MButton onClick={() => skipNow()} width={"large"} variant={"text"}>
                  Skip for now
                </MButton>
              )}

              {isGenericVariation ? (
                <MuiTooltip
                  arrow={false}
                  content={"Regeneration is temporarily disabled to maintain content accuracy for child variations."}
                  placement="top"
                >
                  <MButton width={"large"} disabled={isGenericVariation}>
                    {changeKeywordOwnerId ? "Regenerate" : "Show Results"}
                  </MButton>
                </MuiTooltip>
              ) : (
                <MButton isLoading={isLoading} width={"large"} onClick={handleShowResultHandler}>
                  {changeKeywordOwnerId ? "Regenerate" : "Show Results"}
                </MButton>
              )}
            </div>
          ) : (
            <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0, height: "100px" }} elevation={3}>
              <BottomNavigation
                showLabels
                sx={{
                  position: "absolute",
                  top: "8%",
                  left: "4%",
                  width: "92%",
                }}
              >
                {isActiveAddBtns || isActiveMoveBack ? (
                  <>
                    {isActiveAddBtns ? (
                      <div className={"d-flex gap-3 justify-content-between"}>
                        <div className="w-100">
                          <p className={classes.AddToText}>Add to</p>
                          <MButton onClick={() => moveTo("restKeywords")} size={"small"} variant={"contained"}>
                            {/* Rest of Keywords */}
                            <span className={classes.ClampButton}>Bullet Points + Description Keywords</span>
                          </MButton>
                          <div className="text-center mt-1">
                            {columns.restKeywords.list.length}/{maxTotalKeywordsAllowed - maxSelectedKeywords}
                          </div>
                        </div>
                        <div className="w-100">
                          <p className={classes.MT3}></p>
                          <MButton
                            onClick={() => moveTo("selectedKeywords")}
                            size={"small"}
                            variant={"contained"}
                            rootClass={"w-100"}
                          >
                            Title Keywords
                          </MButton>
                          <div className="text-center mt-1">
                            {columns.selectedKeywords.list.length}/{maxSelectedKeywords}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className={"d-flex gap-3 justify-content-between"}>
                        <div>
                          <p className={classes.MT3}></p>
                          <MButton onClick={() => moveTo("moveBack")} size={"small"} variant={"contained"}>
                            Move back
                          </MButton>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className={"d-flex"}>
                    {changeKeywordOwnerId && (
                      <MButton onClick={handleCancel} width={"large"} size={"small"} variant={"text"}>
                        Cancel
                      </MButton>
                    )}
                    {!changeKeywordOwnerId && (
                      <MButton onClick={() => skipNow()} width={"large"} size={"small"} variant={"text"}>
                        Skip for now
                      </MButton>
                    )}
                    <MButton
                      onClick={handleShowResultHandler}
                      isLoading={isLoading}
                      disabled={isGenericVariation}
                      width={"large"}
                      size={"small"}
                      variant={"contained"}
                      rootClass={"comp-button-mb"}
                    >
                      {changeKeywordOwnerId ? "Regenerate" : "Show Results"}
                    </MButton>
                  </div>
                )}
              </BottomNavigation>
            </Paper>
          )}
        </div>
      )}
    </Box>
  );
};

export default DragAndDrop;

import { IconButton, InputAdornment } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {
  URL_CREATE_COMPANY,
  URL_SIGN_IN,
  // URL_TEAM_MEMBERS
} from "../../routes/routes-path";

import { Navigate, useNavigate, useParams } from "react-router-dom";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { toast } from "react-toastify";

import { ERR_EMAIL, ERR_PASSWORD_EMPTY, ERR_PASSWORD_ENHANCED } from "../../utils/constants/messages/errors";
import { getLastOpenTab, navigatePage } from "../../utils/helpers/common";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/reducers";
import TitleBarLogo from "../../components/Molecules/AppBar/logo";
import MTypography from "../../components/Atoms/MTypography";
import MOutlinedInput from "../../components/Atoms/MOutlinedInput";

import MButton from "../../components/Atoms/MButton";
import roboOnSkate from "../../assets/png/robo-on-skate.png";
import { useDeviceType } from "../../hooks/useDeviceType";

import classes from "./index.module.css";
import { useScreenSize } from "../../hooks/useScreenSize";
import TwoColorText from "../../components/Molecules/TwoColorText";
import { validateEmail } from "../../utils/helpers/general-helpers";
import { setUserCredentials } from "../../store/user/user.actions";
// import { UserApis } from "../../apis/user";
import { firebaseCheckIsEmailAlreadyExist } from "../../firebase/auth";

const SingUpPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { isDesktop, isMobile, isIpad } = useDeviceType();
  const dispatch = useDispatch();

  const { userEmail, companyID, companyName } = useParams();

  const [state, setState] = useState({
    email: userEmail ? userEmail : "",
    password: "",
    loading: false,
    hidden: true,
  });
  const [verfied, setVerifed] = useState(false);

  const storeUser = useSelector((state: AppState) => state.user);

  const screenHeight = useScreenSize();
  const actualHeight = screenHeight < 800 ? 800 : screenHeight;

  useEffect(() => {
    document.title = "Sign Up"
  }, [])

  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    setState({
      ...state,
      [evt.target.name]: evt.target.value,
    });

  const updatePasswordVisibility = () => setState({ ...state, hidden: !state.hidden });

  // @ts-ignore
  const submit = async (): Promise<boolean> => {
    try {
      const { password, email } = state;
      if (password.length < 1) {
        toast.error(ERR_PASSWORD_EMPTY);
        return false;
      }

      if (password.length < 6) {
        toast.error(ERR_PASSWORD_ENHANCED);
        return false;
      }

      if (!validateEmail(email)) {
        toast.error(ERR_EMAIL);
        return false;
      }
 
      if (email.includes("@automatoai.com")) {
        toast.error("Please use another email");
        return false;
      }

      const fres= await firebaseCheckIsEmailAlreadyExist(email)
      if(fres?.length){
        toast.error("User email already exits");
        return false;
      }

      setState({
        ...state,
        loading: true,
      });

      dispatch(setUserCredentials({ email, password, companyID, companyName }));
      setState({ ...state, loading: false });

      // userEmail, companyID, companyName
      if(userEmail && companyID && companyName){
        navigatePage(`${URL_CREATE_COMPANY}/user`, navigate, {
          replace: true,
        });
      }else {
        navigatePage(`${URL_CREATE_COMPANY}`, navigate, {
          replace: true,
        });
      }
    } catch (error: any) {
      toast.error(error);
      setState({ ...state, loading: false });
    }
  };

  function onCaptchaChange(value: any) {
    setVerifed(!!value);
  }

  return storeUser.user ? (
    <Navigate to={getLastOpenTab()} />
  ) : (
    <div
      className={isDesktop ? "d-flex flex-row" : "d-flex flex-column"}
      style={{
        height: `${actualHeight}px`,
        background: "linear-gradient(180deg, #E3D9C6 0.09%, rgba(229, 177, 80, 0.60) 100%)",
      }}
    >
      <div
        style={{
          background: "linear-gradient(180deg, #E3D9C6 0.09%, rgba(229, 177, 80, 0.6) 100%)",
          marginBottom: "-40px",
        }}
      >
        {(isMobile || isIpad) && (
          <div className={"m-4"}>
            <div className="col mx-auto d-flex justify-content-center align-items-center flex-column">
              <img
                src={roboOnSkate}
                alt="robot"
                className="robot"
                style={{ objectFit: "contain", width: "121px", height: "140px", marginBottom: "40px" }}
              />
            </div>
          </div>
        )}
      </div>

      <div className="col" style={{ backgroundColor: "white", borderRadius: isDesktop ? "" : "50px 50px 0px 0px" }}>
        <div className={isDesktop ? "m-5" : `d-flex justify-content-center  ${classes.TitlebarM}`}>
          <TitleBarLogo />
        </div>
        {/*signup-form*/}
        <div className={"d-flex align-items-center justify-content-center"}>
          <div className={`d-flex flex-column text-center ${isDesktop && "w-100"}`}>
            <MTypography variant={"h1"} customClass={isDesktop ? classes.PageTitleD : classes.PageTitleMB}>
              {"Get Started With AutoMato"}
            </MTypography>
            <div className={`${isDesktop ? "my-3" : `${classes.PageCaptionM}`}`}>
              <MTypography variant={"body2"}>{"Getting started is easy"}</MTypography>
            </div>
            <div
              className={
                isDesktop
                  ? `d-flex flex-column align-items-center ${isDesktop && classes.PaddingX9}`
                  : "d-flex flex-column align-items-center"
              }
            >
              <div className={"my-2 w-100"}> 
                <MOutlinedInput
                  id={"email"}
                  name="email"
                  type="email"
                  readonly={userEmail ? true : false}
                  placeholder="Work Email"
                  value={state.email}
                  rootClass={isDesktop ? classes.OutlineInputD : classes.OutlineInputMB}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className={"mt-2 w-100"}>
                <MOutlinedInput
                  id={"password"}
                  name="password"
                  type={state.hidden ? "password" : "text"}
                  placeholder="Password"
                  value={state.password}
                  onChange={handleChange}
                  autoComplete={"new-password"}
                  rootClass={isDesktop ? classes.OutlineInputD : classes.OutlineInputMB}
                  endAdornment={
                    // eslint-disable-next-line
                    <InputAdornment position="end">
                      <IconButton onClick={updatePasswordVisibility}>
                        {state.hidden ? (
                          <VisibilityOffOutlinedIcon />
                        ) : (
                          <VisibilityOutlinedIcon sx={{ color: "primary" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>
            </div>

            {!isDesktop ? (
              <>
                <div className={"d-flex justify-content-center pt-4 pb-3"}>
                  <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY || ""} onChange={onCaptchaChange} />
                </div>
                <div className={`px-3 mb-4 ${classes.CreateButton}`}>
                  <MButton
                    disabled={!verfied}
                    onClick={submit}
                    variant={"contained"}
                    isLoading={state.loading}
                    fullWidth
                  >
                    {"Continue"}
                  </MButton>
                </div>
              </>
            ) : (
              <div className={`d-flex justify-content-between mt-4 mb-4 ${classes.PaddingX9}`}>
                <div className={"d-flex justify-content-center"}>
                  <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY || ""} onChange={onCaptchaChange} />
                </div>
                <div className={` ${classes.CreateButton}`}>
                  <MButton
                    disabled={!verfied}
                    onClick={submit}
                    variant={"contained"}
                    isLoading={state.loading}
                    fullWidth
                    size={"large"}
                  >
                    {"Continue"}
                  </MButton>
                </div>
              </div>
            )}

            <div className={"d-flex justify-content-center"}>
              <div className={"mt-2"} style={isDesktop ? {} : { marginTop: "2rem" }}>
                <TwoColorText
                  text1={"Have an account?"}
                  text2={"Sign in!"}
                  onClick={() => {
                    navigatePage(URL_SIGN_IN, navigate);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*right-side */}
      {isDesktop && (
        <div
          className="col mx-auto d-flex justify-content-center align-items-center flex-column"
          style={{ background: "linear-gradient(180deg, #E3D9C6 0.09%, rgba(229, 177, 80, 0.60) 100%)" }}
        >
          <img src={roboOnSkate} alt="robot" className="robot" style={{ objectFit: "contain", width: "22rem" }} />
          <div className="m-5 text-center">
            <MTypography variant={"h1"} customClass={classes.TextOverImage}>
              {/* {"“The helpful product content optimizer you never knew you needed.”"} */}
              {"Dynamic Content driven by real-time Intelligence."}
            </MTypography>
          </div>
        </div>
      )}
    </div>
  );
};

export default SingUpPage;

import React from "react";
import classes from "./index.module.css";
import { ContentStatusEnum, ProductLiveStatusEnum } from "../../../utils/constants/enums";
import { useDeviceType } from "../../../hooks/useDeviceType";
import { capitalizeEachLetter } from "../../../utils/helpers/strings";
import MTypography from "../../Atoms/MTypography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MuiTooltip from "../../Atoms/MuiTooltip";

interface TagsPros {
  type: ContentStatusEnum | ProductLiveStatusEnum;
  showInfo?: boolean;
  variant?: "small" | "large";
  text?: string;
}
const ProductStatus: React.FC<TagsPros> = ({ showInfo = true, text, variant = "large", type }) => {
  const { isDesktop } = useDeviceType();
  let statusType = "";
  if (type) statusType = capitalizeEachLetter(type.replace("_", " "));
  const showLightClasses =
    type === ProductLiveStatusEnum.LIVE ||
    type === ProductLiveStatusEnum.INACTIVE ||
    type === ProductLiveStatusEnum.NEVER ||
    type === ContentStatusEnum.APPROVED ||
    type === ContentStatusEnum.WAITING ||
    type === ProductLiveStatusEnum.DEACTIVATED ||
    // type === ContentStatusEnum.RESTRICTED ||
    type === ContentStatusEnum.FETCHING ||
    type === ContentStatusEnum.NOT_FOUND ||
    type === ContentStatusEnum.ERROR;
  const StatusContent: any = {
    [ContentStatusEnum.GENERATING]: { name: "Generating", content: "The product content is being generated." },
    [ContentStatusEnum.RESTRICTED]: { name: "ASIN Limit", content: "Content Generation Limit Reached, awaiting renewal or upgrade." },
    [ContentStatusEnum.DRAFT]: {
      name: "Draft",
      content: "The product content has been generated and is ready to be reviewed.",
    },
    [ContentStatusEnum.EDITED]: { name: "Edited", content: "The product content has been edited." },
    [ContentStatusEnum.APPROVED]: { name: "Approved", content: "The product content has been approved." },
    [ContentStatusEnum.FETCH_PRODUCT]: { name: "Fetch Product", content: "Fetch Product" },
    [ContentStatusEnum.ERROR]: {
      name: "Error",
      content: "An error has occurred while generating the product content.",
    },
    [ProductLiveStatusEnum.LIVE]: { name: "Live", content: "The product content is live on Amazon." },
    // shwoing Unpulished instead of Inactive ref: AM-2088
    [ProductLiveStatusEnum.INACTIVE]: { name: "Unpublished", content: "The product content is not live on Amazon." },
    [ProductLiveStatusEnum.CHECKING]: {
      name: "Checking",
      content: "Checking whether the product content is live on Amazon.",
    },
    [ProductLiveStatusEnum.NEVER]: {
      name: "Never",
      content: "This product has not yet been pushed live with AutoMato content.",
    },
    [ProductLiveStatusEnum.PARTIALLY_LIVE]: {
      name: "Partially Live",
      content: "The product content is partially live on Amazon.",
    },
    [ProductLiveStatusEnum.Waiting]: {
      name: "Waiting",
      content: "The system is currently in the process of loading and verifying the existence of your product.",
    },
    [ProductLiveStatusEnum.FETCHING]: {
      name: "Fetching",
      content: "The product is being fetched.",
    },
    [ProductLiveStatusEnum.NOT_FOUND]: {
      name: "Not Found",
      content: "The product is not found, please try again by verifying the entered ASIN.",
    },
    [ContentStatusEnum.PUBLISHED]: {
      name: "Submitted",
      content: "Content has been submitted to Amazon.",
    },
    [ContentStatusEnum.On_HOLD]: {
      name: "On Hold",
      content: "The product is awaiting the parent to be generated before processing.",
    },
    [ProductLiveStatusEnum.DEACTIVATED]: {
      name: "Deactivated",
      content: "This product is deactivated on Amazon.",
    },
  };

  if (!type) {
    return <div />;
  } else {
    return (
      <div
        className={`${
          variant === "large"
            ? isDesktop
              ? classes.TagContainer
              : classes.TagContainerM
            : isDesktop
            ? classes.TagContainerS
            : classes.TagContainerSM
        } ${classes[statusType.replace(" ", "")]}`}
      >
        <MTypography
          variant={"subtitle2"}
          customClass={`${
            variant === "large"
              ? isDesktop
                ? classes.StatusText
                : classes.StatusTextS
              : isDesktop
              ? classes.StatusTextM
              : classes.StatusTextSM
          } ${showLightClasses && classes.LightStatusColor}`}
        >
          {text || StatusContent[type]?.name}
        </MTypography>
        {showInfo && (
          <MuiTooltip arrow={true} content={`${StatusContent[type]?.content}`} placement={"right"}>
            <InfoOutlinedIcon
              fontSize="small"
              className={`d-flex align-items-center justify-content-center cursor-pointer ${
                showLightClasses ? classes.InfoIconLight : classes.InfoIconDark
              } ${(variant === "small" || !isDesktop) && classes.SmallIcon}`}
            />
          </MuiTooltip>
        )}
      </div>
    );
  }
};

export default ProductStatus;

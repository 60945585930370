  import React, { useEffect, useState } from "react";
  import ReactApexChart from "react-apexcharts";
  import { ApexOptions } from "apexcharts";
  import classes from "./index.module.css";
  import moment from "moment";
  import { formatNumberWithCommas, formatNumberWithNearestWholeNumber, formatNumberWithTwoValuesAfterDecimal } from "../../../../../utils/helpers/numbers";

  import { useDeviceType } from "../../../../../hooks/useDeviceType";
  import { bsrPerformanceCheckBoxValues } from "./CheckboxFilters/CheckboxFilters";

  interface BsrPerformanceChartProps {
    chartSeries: any;
    chartCategories: any;
    annotations: any;
    totalTickAmount: any;
    largestNumber: number;
    smallestNumber: number;
    selectedCheckboxes: string;
    bsrSeries: any;
    conversationRate:any;
    traffic:any;
    unitsSold: any;
  }

  const BsrPerformanceOverTime: React.FC<BsrPerformanceChartProps> = ({ unitsSold, bsrSeries,conversationRate,traffic, annotations, chartSeries, chartCategories, selectedCheckboxes }) => {
    const { isMobile } = useDeviceType();

    const maxNumber:any = [...conversationRate].sort((a:any,b:any) => b-a)[0]

    const isConverstionRate = selectedCheckboxes.includes(bsrPerformanceCheckBoxValues.conversationRate)

    const isBsr = selectedCheckboxes === bsrPerformanceCheckBoxValues.bsr

    console.log('test pick bsr chart rendering')

    const [series,setSeries] = useState<any>([{
      name: bsrPerformanceCheckBoxValues.bsr,
      type: "area",
      data: bsrSeries === undefined ?  [] : bsrSeries,
    }, {
      name: bsrPerformanceCheckBoxValues.conversationRate,
      type: "area",
      data: unitsSold === undefined ?  [] : unitsSold,
    }])

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
      if(isBsr) {
        setSeries([{
          name: bsrPerformanceCheckBoxValues.bsr,
          type: "area",
          data: bsrSeries === undefined ?  [] : bsrSeries,
        }, {
          name: bsrPerformanceCheckBoxValues.unitsSold,
          type: "area",
          data: unitsSold === undefined ? [] : unitsSold,
        }])
      }else {
        setSeries([{
          name: bsrPerformanceCheckBoxValues.traffic,
          type: "area",
          data: traffic === undefined ?  [] : traffic,
        }, {
          name: bsrPerformanceCheckBoxValues.conversationRate,
          type: "area",
          data: conversationRate === undefined ? [] : conversationRate,
        }])
      }
    }, [ bsrSeries,conversationRate,traffic, annotations, chartSeries, chartCategories, selectedCheckboxes ]);



    const leftSideTicks:any = {
      reversed: isBsr,
      title: {
        text: isBsr ?  "High Sales" : '',
        offsetY: -180,
        offsetX: selectedCheckboxes.length > 1 ? 14 : 20,
        rotate: 0,
        style: {
          color: "#0CC683",
          fontSize: "12px",
          fontWeight: 600,
        },
      },
      forceNiceScale: true,
      labels: {
        minWidth: isBsr ? 0 : 80,
        maxWidth: isMobile ? 60 : 100,
        align: "left",
        offsetX: isMobile ? -20 : -15,
        style: {
          colors: "#4F4F4F",
          fontSize: isMobile ? "8px" : "10px",
          fontWeight: 600,
          fontFamily: "Poppins",
        },
        formatter(val: number): any {
          // eslint-disable-next-line eqeqeq
          if (val == 0) {
            return isBsr ? "1" : '0';
          } else {
            return formatNumberWithCommas(Math.round(val));
          }
        },
      },
    }

    const rightSideTicks:any = {
      // reversed: true,
      opposite:true,
      decimalsInFloat: isConverstionRate ? 2 : 0,
      showForNullSeries: true,
      min: 0,
      // floating: true,
      [isConverstionRate ?'max' : '']: isConverstionRate ? maxNumber + (maxNumber*0.1) : isConverstionRate ,
      title: {
        rotate: 0,
        style: {
          color: "#0CC683",
          fontSize: "12px",
          fontWeight: 600,
        },
      },
      forceNiceScale: true,
      labels: {
        minWidth: 0,
        maxWidth: isMobile ? 60 : 60,
        align: "left",
        // offsetX: isMobile ? -20 : -15,
        style: {
          colors: "#4F4F4F",
          fontSize: isMobile ? "8px" : "10px",
          fontWeight: 600,
          fontFamily: "Poppins",
        },
        formatter(val: number): any {
          // eslint-disable-next-line eqeqeq
          if (val == 0) {
            return  "0";
          } else {
            return !isBsr ? formatNumberWithNearestWholeNumber(val || 0)+ '%' : formatNumberWithTwoValuesAfterDecimal(val || 0);
          }
        },
      },
    }

    const getOtherValues = (dataPointIndex:any) => {
      if(isBsr){
        return `<div>
        ${unitsSold[dataPointIndex] !== null  ? `<span class=${classes.TooTipInfoTitle}>Units Sold :</span>
          <span class=${
          classes.TooTipInfoText
        }> ${unitsSold[dataPointIndex]} </span>` : '' }
          
</div>`
      }else{
        return `
        ${traffic[dataPointIndex] !== null ? `<div>
          <span class=${classes.TooTipInfoTitle}>Traffic: </span>
          <span class=${
            classes.TooTipInfoText
          }> ${formatNumberWithNearestWholeNumber(traffic[dataPointIndex])} </span>

        </div> `: ''}
        ${
          conversationRate[dataPointIndex] !== null ? `<div>
            <span class=${classes.TooTipInfoTitle}>Conversion Rate :</span>
            <span class=${
            classes.TooTipInfoText
          }> ${formatNumberWithNearestWholeNumber(conversationRate[dataPointIndex])}% </span>

          </div>` : ""
        }
         `

      }
    }


    const options: ApexOptions = {
      chart: {
        type: "area",
        height: 400,
        stacked: false,
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      annotations: {
        xaxis: annotations,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 4,
        curve: "smooth",
      },
      plotOptions: {
        bar: {
          columnWidth: "15%",
        },
      },
      grid: {
        show: true,
        borderColor: "#D8D8D8",
        strokeDashArray: 2,
        yaxis: {
          lines: {
            show: true,
          },
        },
        padding: {
          top: 20,
          right: isMobile ? 20 : 45,
          bottom: 0,
          left: 0,
        },
      },
      fill: {
        type: "solid",
        colors: ["transparent"],
      },
      xaxis: {
        type: "category",
        categories: chartCategories,
        tickAmount: 10,
        labels: {
          hideOverlappingLabels: true,
          showDuplicates: false,
          minHeight: 50,
          style: {
            colors: "#4F4F4F",
            fontSize: "10px",
            fontWeight: 600,
            fontFamily: "Poppins",
          },
          formatter: (value) => {
            return moment(value).format("DD MMM");
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: series.length > 1 ? [{ ...leftSideTicks }, { ...rightSideTicks }] : { ...leftSideTicks },
      legend: {
        show: false,
      },
      tooltip: {
        shared: true,
        intersect: false,
        followCursor: true,
        custom: function ({ dataPointIndex }) {
          
          // to get title from bottom annotations
          const formattedDate = moment(chartCategories[dataPointIndex], 'YYYY-MM-DD').format('D MMM');
          const filtered = annotations?.filter((obj: any) => obj.x === formattedDate && obj.label.position === "bottom");
          const title = filtered[0]?.label?.text
          
          const bsrValue = formatNumberWithCommas(bsrSeries[dataPointIndex]);
          const titleTooltipPart = title ? (
            `<div>
            <hr class=${classes.CustomDivider} />
            <span class="${classes.TooTipInfoTitle} mt-0">
            Title Changed:</span>
            <div class='fw-semibold'>
            ${title}
            </div>
            </div> 
            `
          ) : (
            `<div></div>`
          );

          let tooltipContent = `<div class=${classes.TooltipCard}>`;
          tooltipContent += "<div class='d-flex flex-column align-content-center'>";
          tooltipContent += `
            ${isBsr ?  `<div class="">
              ${
            bsrValue !== null ?
              `<span class=${classes.TooTipInfoTitle}>BSR :</span><span class=${classes.TooTipInfoText}># ${
                formatNumberWithCommas( bsrSeries[dataPointIndex])} 
              </span>`
              :
              ""
          }
            </div> ` : ''}
      ${getOtherValues(dataPointIndex)}`
          ;
          tooltipContent += "</div>";
          tooltipContent += "<div class='d-flex mt-1 align-content-center'>";
          tooltipContent += `<span class=${classes.TooTipInfoTitle}>Date :</span><span class=${
            classes.TooTipInfoText
          }>${moment(chartCategories[dataPointIndex]).format("DD MMM, YYYY")}</span>`;
          tooltipContent += "</div>";
          tooltipContent += titleTooltipPart;
          tooltipContent += "</div>";
          return tooltipContent;
        },
      },
      colors: [
        selectedCheckboxes === bsrPerformanceCheckBoxValues.bsr ?  "#ff0000" : '#90BE6D',
        selectedCheckboxes === bsrPerformanceCheckBoxValues.bsr ? "#D87B00" : "#4285F4",
      ],
    };
    return (
      <div>
        <ReactApexChart options={options} series={series} type="line" height={400} />
        {isBsr ? <p className={classes.Title}>Low Sales</p> : <p className={classes.Title}></p>}
      </div>
    );
  };
  export default BsrPerformanceOverTime;

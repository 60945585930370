import React, { useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import {
  URL_PRODUCTS,
  URL_FETCH_ASIN,
  URL_FORGOT_PASSWORD,
  URL_KEYWORDS,
  URL_LANDING_PAGE,
  URL_PRODUCT_CATALOG,
  URL_PRODUCT_FETCHED,
  URL_PRODUCT_FETCHED_COMPETITORS,
  URL_SIGN_IN,
  URL_SIGN_UP,
  PRIORITISE_KEYWORDS,
  URL_ACCOUNT_STATUS,
  URL_OPPORTUNITY_REPORT,
  URL_UNSUBSCRIBE,
  URL_CONGRATS,
  // URL_REGISTER,
  URL_TEAM_MEMBERS,
  URL_CREATE_COMPANY,
  URL_CONTACT_US,
} from "./routes-path";
import ProtectRoute from "./protect-route";
import FetchAsinPage from "../pages/Product/fetchAsin";
import CompetitorsPage from "../pages/Product/competitors";
import KeywordsPage from "../pages/Product/keywords";
import SignInPage from "../pages/SignIn";
import SingUpPage from "../pages/SignUp";
import ForgotPasswordPage from "../pages/ForgotPassword";
import OpportunityReportPage from "../pages/OpportunityReportPage/OpportunityReportPage";
import ErrorPage from "../pages/Error";
import SavedProductsTab from "../pages/SavedProducts";
import BlacklistWords from "../pages/KeywordBlocklist";
import AccountManagement from "../pages/AccountManagement";
import PrioritiseKeywordsPage from "../pages/Product/PrioritiseKeywordsPage";
import TeamManagementUser from "../pages/Settings/TeamManagementUser";
import {
  CatalogEnum,
  ParamsEnum,
  RolesEnum,
  // RegistersEnum,
  SettingsEnum,
  TabsEnum,
} from "../utils/constants/enums";
import { KEY_USER_ACCESS_TOKEN } from "../utils/constants/store";
import MoreTab from "../pages/MoreTab";
import SettingsTab from "../pages/Settings";
import BrandSettings from "../pages/Settings/BrandSettings";
import AccountStatus from "../pages/AccountStatus";
import ProductDetails from "../pages/Product/ProductDetails";
import Dialog from "../components/Atoms/Dialog";
import MButton from "../components/Atoms/MButton";
import UnsubscribePage from "../pages/Unsubscribe/Unsubscribe";
import Profile from "../pages/Settings/Profile";
import { StripePricingTable } from "../components/Organisms/Stripe/StripePricingTable";

import PricingContext from "../context/PricingContext";
import Brands from "../components/Organisms/SavedProducts/Brands";
import GoGoAutomatoSettings from "../pages/Settings/GoGoAutomatoSettings";
import AccessKeys from "../pages/Settings/AccessKeys/AccessKeys";
import AutomatoApiAccess from "../components/Organisms/AccessKeys/AutomatoApiAccess/AutomatoApiAccess";
import BrandsMenu from "../pages/Settings/BrandsMenu/BrandsMenu";
import BrandAliasManagement from "../components/Organisms/BrandSettings/BrandAliasManagement/BrandAliasManagement";
// import RegisterPage from "../pages/Register";
import AddTeamMembersPage from "../pages/AddTeamMembers";
import CreateCompany from "../pages/SignUp/CreateCompany";
import TeamManagement from "../pages/Settings/TeamManagement/TeamManagement";
import { useSelector } from "react-redux";
import { UserRoleSelector } from "../store/user/user.selector";
import ClientsTab from "../pages/Clients";
import ClientDetail from "../pages/ClientDetail";
import AddInternalUser from "../pages/AccountManagement/AddInternalUser/AddInternalUser";
import Amazon from "../pages/amazon";

import BrandVoice from "../pages/Settings/BrandVoice";
import BrandVoiceStarted from "../components/Organisms/BrandSettings/BrandVoice/BrandVoiceStarted";
import BrandAlaisForm from "../pages/Settings/AliasManagement/BrandAlaisForm";
import AiModelManagement from "../pages/Settings/AiModelManagement";
import ContactUs from "../pages/ContactUs/ContactUs";
import PublishAccess from "../pages/Settings/PublishAccess";

const AppRoutes: React.FC = () => {
  const { onClose, isOpen } = useContext(PricingContext);
  const isAuthenticated =
    sessionStorage.getItem(KEY_USER_ACCESS_TOKEN)?.length || localStorage.getItem(KEY_USER_ACCESS_TOKEN)?.length;

  const userRole = useSelector(UserRoleSelector);

  return (
    <>
      <Dialog title={""} onClose={onClose} isOpen={isOpen}>
        <div className={"p-4"}>
          <StripePricingTable />
          <div className={"d-flex justify-content-end"}>
            <MButton
              onClick={() => {
                onClose();
              }}
              size={"small"}
              color={"primary"}
            >
              Close
            </MButton>
          </div>
        </div>
      </Dialog>
      <Routes>
        <Route path={`${URL_CONTACT_US}`} element={<ProtectRoute Component={<ContactUs />} />} />
        <Route
          path={`${URL_PRODUCTS}/:${ParamsEnum.BRAND}`}
          element={<ProtectRoute Component={<SavedProductsTab />} />}
        />

        <Route path={URL_PRODUCTS} element={<ProtectRoute Component={<Brands />} />} />
        <Route
          path={`${URL_OPPORTUNITY_REPORT}/:${ParamsEnum.BRAND}`}
          element={<ProtectRoute Component={<OpportunityReportPage />} />}
        />
        <Route path={URL_OPPORTUNITY_REPORT} element={<ProtectRoute Component={<OpportunityReportPage />} />} />

        <Route path={`${URL_LANDING_PAGE}/${TabsEnum.MORE}`} element={<ProtectRoute Component={<MoreTab />} />} />

        <Route
          path={`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}`}
          element={<ProtectRoute Component={<SettingsTab />} />}
        />

        <Route
          path={`${URL_LANDING_PAGE}/${TabsEnum.BLACKLIST}`}
          element={<ProtectRoute Component={<BlacklistWords />} />}
        />

        <Route
          path={`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${SettingsEnum.USERS}`}
          element={<ProtectRoute Component={<AccountManagement />} />}
        />
        <Route path={`${URL_LANDING_PAGE}/amazon`} element={<ProtectRoute Component={<Amazon />} />} />
        <Route
          path={`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${SettingsEnum.USERS}/${SettingsEnum.ADD_INTERNAL_USER}`}
          element={<ProtectRoute Component={<AddInternalUser />} />}
        />

        <Route path={`${URL_LANDING_PAGE}/${TabsEnum.CLIENTS}`} element={<ProtectRoute Component={<ClientsTab />} />} />

        <Route
          path={`${URL_LANDING_PAGE}/${TabsEnum.CLIENTS}/company/:id`}
          element={<ProtectRoute Component={<ClientDetail isCompany />} />}
        />
        <Route
          path={`${URL_LANDING_PAGE}/${TabsEnum.CLIENTS}/team-management/:id`}
          element={<ProtectRoute Component={<TeamManagementUser />} />}
        />
        <Route
          path={`${URL_LANDING_PAGE}/${TabsEnum.CLIENTS}/individual/:id`}
          element={<ProtectRoute Component={<ClientDetail isCompany={false} />} />}
        />

        {/* working */}
        <Route
          path={`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/:${SettingsEnum.BRAND}`}
          element={<ProtectRoute Component={<BrandsMenu />} />}
        />

        <Route
          path={`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/:${SettingsEnum.ADD_BRAND_VOICE}`}
          element={<ProtectRoute Component={<BrandVoice />} />}
        />
        <Route
          path={`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/:${SettingsEnum.BRAND_VOICE}`}
          element={<ProtectRoute Component={<BrandVoiceStarted />} />}
        />
        {/* only for company_admin */}
        {userRole === RolesEnum.COMPANY_ADMIN && (
          <>
            <Route
              path={`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${SettingsEnum.TEAM_MANAGEMENT}`}
              element={<ProtectRoute Component={<TeamManagement />} />}
            />
            <Route
              path={`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${SettingsEnum.COMPANY_PROFILE}`}
              element={<ProtectRoute Component={<Profile type={"companyProfile"} />} />}
            />
          </>
        )}
        <Route
          path={`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${SettingsEnum.TEAM_MANAGEMENT}/:id`}
          element={<ProtectRoute Component={<TeamManagementUser />} />}
        />
        <Route
          path={`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${SettingsEnum.CONTENT_FORMAT_SETTINGS}`}
          element={<ProtectRoute Component={<BrandSettings />} />}
        />
        <Route
          path={`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${SettingsEnum.BRAND_ALIAS_MANAGEMENT}`}
          element={<ProtectRoute Component={<BrandAliasManagement />} />}
        />

        <Route
          path={`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${SettingsEnum.BRAND_ALIAS_MANAGEMENT}/:id`}
          element={<ProtectRoute Component={<BrandAlaisForm />} />}
        />

        <Route
          path={`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${SettingsEnum.GoGoAutomato}`}
          element={<ProtectRoute Component={<GoGoAutomatoSettings />} />}
        />
        {/* for access key route */}
        <Route
          path={`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${SettingsEnum.API_ACCESS_KEYS}`}
          element={<ProtectRoute Component={<AccessKeys />} />}
        />
        <Route
          path={`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${SettingsEnum.AUTOMATO_API_ACCESS}`}
          element={<ProtectRoute Component={<AutomatoApiAccess />} />}
        />
        <Route
          path={`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${SettingsEnum.MANAGE_PUBLISH_ACCESS}`}
          element={<ProtectRoute Component={<PublishAccess />} />}
        />
        <Route
          path={`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${SettingsEnum.USER_PROFILE}`}
          element={<ProtectRoute Component={<Profile type={"userProfile"} />} />}
        />
        <Route
          path={`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}/${SettingsEnum.AI_MODEL_MANAGEMENT}`}
          element={<ProtectRoute Component={<AiModelManagement />} />}
        />

        <Route path={URL_FETCH_ASIN} element={<ProtectRoute Component={<FetchAsinPage />} />} />
        <Route
          path={`${URL_PRODUCT_FETCHED}/:${ParamsEnum.DOMAIN}/:${ParamsEnum.ASIN}${URL_PRODUCT_FETCHED_COMPETITORS}`}
          element={<ProtectRoute Component={<CompetitorsPage />} />}
        />
        <Route
          path={`${URL_PRODUCT_FETCHED}/:${ParamsEnum.DOMAIN}/:${ParamsEnum.ASIN}${PRIORITISE_KEYWORDS}`}
          element={<ProtectRoute Component={<PrioritiseKeywordsPage />} />}
        />
        <Route
          path={`${URL_PRODUCT_FETCHED}/:${ParamsEnum.DOMAIN}/:${ParamsEnum.ASIN}${URL_KEYWORDS}`}
          element={<ProtectRoute Component={<KeywordsPage />} />}
        />
        <Route path={`${URL_UNSUBSCRIBE}`} element={<UnsubscribePage />} />
        <Route
          path={`${URL_CONGRATS}`}
          element={<ProtectRoute Component={<UnsubscribePage pageType={"signup"} />} />}
        />
        <Route
          path={`${URL_PRODUCT_CATALOG}/${CatalogEnum.DETAIL_PAGE}/:${ParamsEnum.ASIN}/:${ParamsEnum.USER_ID}`}
          element={<ProtectRoute Component={<ProductDetails />} />}
        />

        {/* <Route path={URL_REGISTER} element={<RegisterPage />} /> */}
        <Route path={URL_SIGN_IN} element={<SignInPage />} />
        {/* <Route path={`${URL_SIGN_UP}/:${RegistersEnum.INDIVIDUAL}`} element={<SingUpPage />} /> */}
        <Route path={`${URL_SIGN_UP}`} element={<SingUpPage />} />
        <Route path={`${URL_SIGN_UP}/:companyName/:companyID/:userEmail`} element={<SingUpPage />} />
        <Route path={`${URL_CREATE_COMPANY}/:user?`} element={<CreateCompany />} />
        <Route path={`${URL_TEAM_MEMBERS}`} element={<AddTeamMembersPage />} />
        <Route path={URL_ACCOUNT_STATUS} element={<AccountStatus />} />
        <Route path={URL_FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
        <Route
          path="/"
          element={isAuthenticated ? <Navigate to={URL_PRODUCTS} replace /> : <Navigate to={URL_SIGN_IN} replace />}
        />
        <Route path="/*" element={isAuthenticated ? <Navigate to={URL_PRODUCTS} replace /> : <ErrorPage />} />
      </Routes>
    </>
  );
};

export default AppRoutes;

import * as yup from "yup";

export interface PublishAccessFormObjInterface {
  instructions: string;
  isAllowed: boolean;
}

export const inputName = {
  instructions: "name",
  isAllowed: "isAllowed",
};

export const initialValue: PublishAccessFormObjInterface = {
  instructions: "",
  isAllowed: false,
};

export const validationSchemaForPublishAccess = yup.object().shape({
  instructions: yup.string().optional(),
  isAllowed: yup.boolean().required(),
});


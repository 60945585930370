import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { InputAdornment, IconButton } from "@mui/material";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

import { URL_FORGOT_PASSWORD, URL_LANDING_PAGE } from "../../routes/routes-path";
import { getLastOpenTab, navigatePage } from "../../utils/helpers/common";
import { TabsEnum } from "../../utils/constants/enums";
import { establishUserLoginSession } from "../../apis/auth";
import { setRoleDispatch, setUserDispatch } from "../../store/user/user.actions";
import { ERR_EMAIL_EMPTY, ERR_PASSWORD_EMPTY } from "../../utils/constants/messages/errors";
import roboInSpace from "../../assets/png/robo.png";
import { useDeviceType } from "../../hooks/useDeviceType";
import TitleBarLogo from "../../components/Molecules/AppBar/logo";
import MButton from "../../components/Atoms/MButton";
import TwoColorText from "../../components/Molecules/TwoColorText";
import MOutlinedInput from "../../components/Atoms/MOutlinedInput";
import MTypography from "../../components/Atoms/MTypography";

import classes from "./index.module.css";
import { errorHandler } from "../../utils/helpers/apis";
import { setCompanyAction } from "../../store/company/company.actions";

interface SignInPageProps {}

interface SignInState {
  email: string;
  password: string;
  loading: boolean;
  hidden: boolean;
  userAuthorized: boolean;
}

export const SignInPage = (_props: SignInPageProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isDesktop, isMobile, isIpad } = useDeviceType();
  const [state, setState] = useState<SignInState>({
    email: "",
    password: "",
    loading: false,
    hidden: true,
    userAuthorized: false,
  });

  useEffect(() => {
    document.title = "Sign in";
  }, []);

  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    setState({
      ...state,
      [evt.target.name]: evt.target.value,
    });

  const authenticateUser = async () => {
    if (state.email && state.password) {
      setState({
        ...state,
        loading: true,
      });

      establishUserLoginSession(state.email, state.password)
        .then((resp) => {
          if (resp?._id) {
            dispatch(setUserDispatch(resp));
            dispatch(setRoleDispatch(resp?.role));
            if (resp?.companyID) {
              dispatch(setCompanyAction(resp?.companyID));
            }
            navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.SAVED}`, navigate, {
              replace: true,
            });
            setState({ ...state, loading: false, userAuthorized: true });
          }
        })
        .catch((error: any) => {
          setState({ ...state, loading: false });
          toast.error(errorHandler(error));
        });
    } else {
      if (!state.email) toast.error(ERR_EMAIL_EMPTY);
      else toast.error(ERR_PASSWORD_EMPTY);
    }
  };

  const updatePasswordVisibility = () => setState({ ...state, hidden: !state.hidden });

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      authenticateUser();
    }
  };

  return (
    <div>
      {state.userAuthorized ? (
        <Navigate to={getLastOpenTab()} replace={true} />
      ) : (
        <div
          className={isDesktop ? "d-flex flex-row" : "d-flex flex-column"}
          style={{
            height: "100vh",
            background: "linear-gradient(180deg, #E3D9C6 0.09%, rgba(229, 177, 80, 0.60) 100%)",
          }}
        >
          <div
            style={{
              background: "linear-gradient(180deg, #E3D9C6 0.09%, rgba(229, 177, 80, 0.6) 100%)",
              marginBottom: "-40px",
            }}
          >
            {(isMobile || isIpad) && (
              <div className={"m-4"}>
                <div className="col mx-auto d-flex justify-content-center align-items-center flex-column">
                  <img
                    src={roboInSpace}
                    alt="robot"
                    className="robot"
                    style={{ objectFit: "contain", width: "121px", height: "140px", marginBottom: "40px" }}
                  />
                </div>
              </div>
            )}
          </div>

          <div
            className="col"
            style={{ backgroundColor: "white", borderRadius: isDesktop ? "" : "50px 50px 0px 0px" }}
            onKeyDown={handleKeyDown}
          >
            <div className={isDesktop ? "m-5" : `d-flex justify-content-center  ${classes.TitlebarM}`}>
              <TitleBarLogo />
            </div>
            {/*login-form*/}
            <div className={"d-flex align-items-center justify-content-center"}>
              <div className={`d-flex flex-column text-center ${isDesktop && "w-100"}`}>
                <MTypography variant={"h1"} customClass={isDesktop ? classes.PageTitleD : classes.PageTitleMB}>
                  {"Welcome Back"}
                </MTypography>
                <div className={`${isDesktop ? "my-3" : `${classes.PageCaptionM}`}`}>
                  <MTypography variant={"body2"}>{"Log in to your account"}</MTypography>
                </div>
                <div
                  className={
                    isDesktop
                      ? `d-flex flex-column align-items-center ${isDesktop && classes.PaddingX9}`
                      : "d-flex flex-column align-items-center"
                  }
                >
                  <div className={"my-2 w-100"}>
                    <MOutlinedInput
                      id={"email"}
                      name="email"
                      type="email"
                      placeholder="Work Email"
                      value={state.email}
                      rootClass={isDesktop ? classes.OutlineInputD : classes.OutlineInputMB}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className={"my-2 w-100"}>
                    <MOutlinedInput
                      id={"password"}
                      name="password"
                      type={state.hidden ? "password" : "text"}
                      placeholder="Password"
                      value={state.password}
                      onChange={handleChange}
                      rootClass={isDesktop ? classes.OutlineInputD : classes.OutlineInputMB}
                      endAdornment={
                        // eslint-disable-next-line
                        <InputAdornment position="end">
                          <IconButton onClick={updatePasswordVisibility}>
                            {state.hidden ? (
                              <VisibilityOffOutlinedIcon />
                            ) : (
                              <VisibilityOutlinedIcon sx={{ color: "primary" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </div>
                </div>
                <div className={`${isDesktop && classes.PaddingX9}`}>
                  <div
                    onClick={() => {
                      navigatePage(URL_FORGOT_PASSWORD, navigate);
                    }}
                    className="d-flex justify-content-end mt-2 mb-4"
                  >
                    <MTypography variant={"subtitle1"} customClass={classes.ForgotPassword}>
                      Forgot Password?
                    </MTypography>
                  </div>

                  <MButton fullWidth onClick={authenticateUser} variant={"contained"} isLoading={state.loading}>
                    {"Log In"}
                  </MButton>
                  <div style={isDesktop ? { marginLeft: "30rem" } : { marginLeft: "20rem" }}></div>

                  <div className={"d-flex justify-content-center"}>
                    <div className={"mb-5"} style={{ marginTop: "2rem" }}>
                      <TwoColorText
                        text1={"Don't have an account?"}
                        text2={"Sign up!"}
                        onClick={() => {
                          // navigatePage(URL_REGISTER, navigate);
                          window.location.href = 'https://www.automatoai.com/signup'
                          // navigatePage(`${URL_SIGN_UP}`, navigate);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*right-side*/}
          {isDesktop && (
            <div
              className="col mx-auto d-flex justify-content-center align-items-center flex-column"
              style={{ background: "linear-gradient(180deg, #E3D9C6 0.09%, rgba(229, 177, 80, 0.6) 100%)" }}
            >
              <img src={roboInSpace} alt="robot" className="robot" style={{ objectFit: "contain", width: "16rem" }} />
              <div className="m-5 text-center">
                <MTypography variant={"h1"} customClass={classes.TextOverImage}>
                  {"Mission Control for Organic Traffic and Product Content."}
                </MTypography>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SignInPage;

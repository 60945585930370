import React, { useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDeviceType } from "../../../hooks/useDeviceType";
import { URL_LANDING_PAGE } from "../../../routes/routes-path";
import { SettingsEnum, TabsEnum } from "../../../utils/constants/enums";
import { navigatePage } from "../../../utils/helpers/common";
import { capitalizeEachLetter } from "../../../utils/helpers/strings";
import TabsToolbar from "../../../components/Molecules/TabsToolbar";
import MTypography from "../../../components/Atoms/MTypography";
import FormSwitch from "../../../components/Molecules/FormSwitch/FormSwitch";
import MButton from "../../../components/Atoms/MButton";
import FormTextField from "../../../components/Molecules/FormTextField";
import FormTitleWithLabel from "../../../components/Molecules/FormTitleWithLabel";
import { initialValue, inputName, PublishAccessFormObjInterface, validationSchemaForPublishAccess } from "./config";
import classes from "./index.module.css";

const PublishAccess = () => {
  const { isDesktop } = useDeviceType();
  const navigate = useNavigate();
  const [initialFormValue, setInitialFormValue] = useState<PublishAccessFormObjInterface>(initialValue);

  const navigateToSettingsTab = () => {
    navigatePage(`${URL_LANDING_PAGE}/${TabsEnum.SETTINGS}`, navigate);
  };

  const submitForm = async (
    values: PublishAccessFormObjInterface,
    { resetForm }: FormikHelpers<PublishAccessFormObjInterface>
  ) => {
    try {
      // Replace this with your form submission logic
      console.log("Submitted values:", values, setInitialFormValue);

      // Optionally reset the form
      resetForm();
    } catch (error) {
      console.error("Form submission failed:", error);
    }
  };

  return (
    <div className="w-100">
      {isDesktop ? (
        <TabsToolbar
          title={capitalizeEachLetter(SettingsEnum.MANAGE_PUBLISH_ACCESS.replace(/-/g, " "))}
          avoidCapitalization={true}
          children={
            <div className={"cursor-pointer"} onClick={navigateToSettingsTab}>
              <MTypography variant={"subtitle2"}>{"< Back"}</MTypography>
            </div>
          }
        />
      ) : (
        <div className={"d-flex flex-column p-2 m-2"}>
          <div className={"my-2 mx-2"}>
            <ArrowBackIcon onClick={navigateToSettingsTab} />
          </div>
          <div className={"my-2 mx-2"}>
            <MTypography variant={"h5"}>
              {capitalizeEachLetter(SettingsEnum.MANAGE_PUBLISH_ACCESS.replace(/-/g, " "))}
            </MTypography>
          </div>
        </div>
      )}

      <div>
        <Formik
          enableReinitialize
          initialValues={initialFormValue}
          onSubmit={submitForm}
          validationSchema={validationSchemaForPublishAccess}
        >
          {({ handleSubmit, dirty, isValid }) => (
            <Form autoComplete="off">
              <div className={`${isDesktop && "pt-4"} px-4 w-100`}>
                <div className="d-flex justify-content-between align-items-center gap-2">
                  <MTypography variant="subtitle2">I agree to allow AutoMato to publish my content automatically without further approvals.</MTypography>
                  <FormSwitch label={""} name={inputName.isAllowed} />
                </div>
                <div className="pt-2">
                  <div className="d-flex align-items-center gap-2">
                    <FormTitleWithLabel title="Additional Instructions" label={""} />
                    <div className={`${isDesktop ? classes.Optional : classes.OptionalM }`}>{`(Optional)`}</div>
                  </div>
                  <FormTitleWithLabel
                    isSubtitle
                    title="You can add the rules about the content that should be published."
                    label={""}
                  />
                  <FormTextField fullWidth name={inputName.instructions} label={""} multiline rows={3} placeholder="Add Instructions" />
                </div>
                <div className="d-flex justify-content-end mt-4">
                  <MButton
                    // isLoading={isLoading}
                    size={"large"}
                    variant={"contained"}
                    onClick={handleSubmit}
                    disabled={!dirty || !isValid}
                  >
                    Save
                  </MButton>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default PublishAccess;

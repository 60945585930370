import React, { memo, useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { IconButton, Tooltip } from "@mui/material";
import { GridCellParams, GridColDef, GridSelectionModel } from "@mui/x-data-grid";
import OnlinePredictionOutlinedIcon from "@mui/icons-material/OnlinePredictionOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
// import RefreshIcon from "@mui/icons-material/Refresh";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FilterListIcon from "@mui/icons-material/FilterList";
import CachedIcon from "@mui/icons-material/Cached";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import DeleteIcon from "@mui/icons-material/Delete";

import { toDateAndTimeObject } from "../../../../utils/helpers/date";
import { copyAsinByRightClick, getExceptionError, navigatePage } from "../../../../utils/helpers/common";
import { SUCC_CSV_EXPORT, SUCC_DELETE_PRODUCT } from "../../../../utils/constants/messages/success";
import { defaultImage } from "../../../../utils/constants/general-constants";
import { errorHandler } from "../../../../utils/helpers/apis";
import { capitalizeEachLetter } from "../../../../utils/helpers/strings";
import { generatingProcessTimeTenToFifteen } from "../../../../utils/constants/common";
import { onImgErrorHandler, reduceImgSize } from "../../../../utils/helpers/general-helpers";
import {
  ERR_CSV_EXPORT,
  UnlockGreaterPossibilities,
  UserReachedRegenLimit,
} from "../../../../utils/constants/messages/errors";
import {
  isExportButton,
  productActionsMenuOnSavedProduct,
  productActionsMenuOnSavedProductForCompany,
  showAddTagOption,
  showDeleteIconOnProductPage,
  showMultipleProductDeleteButton,
  showMultipleProductDeleteButtonCompany,
  // showPublishOnAmazonButton,
  showRerunButton,
  showUserEmailInProductsPage,
  showWaitingGridOnClientsForCompany,
  userRole as userRoles,
} from "../../../../utils/helpers/priviligesChecks";
import {
  CatalogEnum,
  ContentStatusEnum,
  CountryDomainEnum,
  ProductFetchType,
  ProductLiveStatusEnum,
  RolesEnum,
  TabsEnum,
} from "../../../../utils/constants/enums";

import { URL_PRODUCT_CATALOG } from "../../../../routes/routes-path";
import { Product } from "../../../../apis/types/generate-product";
import {
  generateProductUsingOpenAIByKeywordsTitleBulletPointsAndDescription,
  ProductApis,
} from "../../../../apis/product";

import KeywordsPageDialog from "../../../mui/dialogebox/keywordsPageDialoge";
import ExportAsinsDialoge, { exportCsvType } from "../../../mui/dialogebox/exportAsinsDialoge";

import {
  checkSelectedProductsLiveActions,
  getUserProductAction,
  savedProductsDispatch,
  setChangeKeywordsProductOwnerIdDispatch,
  setProductSearchFilterDispatch,
  setProductSearchResetDispatch,
  setProductsPaginationDispatch,
} from "../../../../store/product/product.actions";

import {
  ProductSearchFilterSelector,
  ProductsPaginationSelector,
  SavedProductsSelector,
  UserProductTagsSelector,
} from "../../../../store/product/product.selector";
import { DbUserSelector, UserRoleSelector } from "../../../../store/user/user.selector";
import { CompanyRoleSelector } from "../../../../store/company/company.selector";
import { AppState } from "../../../../store/reducers";

import PricingContext from "../../../../context/PricingContext";

import { generateProductApis } from "../../../../apis/generate-product";
import { KeywordsApis } from "../../../../apis/keywords";

import { usePagination } from "../../../../hooks/usePagination";

import {
  checkIsCompanyReachedHelium10Limit,
  checkIsCompanyReachedHeliumOrContentRegenLimit,
  checkIsProductOwnerReachedHisLimit,
  checkIsUserReachedHisHelium10Limit,
  checkLiveProductLimit,
  downloadSavedProductCsv,
  downloadSelectedProductsCsv,
} from "../../../../pages/SavedProducts/config";
import { handleChangeKeywordsClick, showChangeKeywordButton } from "../../../../pages/Product/ProductDetails/config";
import { createKeywordObject } from "../../../../pages/Product/keywords/config";

import MTypography from "../../../Atoms/MTypography";
import MTextField from "../../../Atoms/MTextField";
import MuiPopover from "../../../Atoms/Popover";
import MuiTooltip from "../../../Atoms/MuiTooltip";
import ToastError from "../../../Atoms/ToastError";

import ProductStatus from "../../../Molecules/ProductStatus";
import ActionBox from "../../../Molecules/ActionBox";
import FilterMenu from "../../../Molecules/FilterMenu";

import changeKeywordIconActive from "../../../../assets/svgs/change-keyword-icon-active.svg";

import { CountryDomainObject, getCountryDomainObjectByName } from "../../DomainDropDown/config";
import TagPopper from "../TagPopper";
import { getAllTagsOfProduct } from "../TagPopper/config";
import WaitingGrid from "../WaitingGrid/WaitingGrid";
import { contentFilters, contentFiltersOption } from "../Brands/config";
import GridSkeleton from "../GridSkeleton/GridSkeleton";

import ProductTable from "./ProductTable";
import classes from "./index.module.css";
import ProductActionsMenu from "../ProductActionsMenu";
import ExportAllAsinsDialoge, { exportAllProductCsvType } from "../../../mui/dialogebox/exportAllAsinsDialoge";

type ProductDataGridState = {
  loading: boolean;
  openDialogBox: boolean;
  openDeleteConfirm: boolean;
  currentDeleteId: string;
};
type ProductDataGridProps = {
  tab: TabsEnum;
  isRerun?: boolean;
  isChildComponent?: boolean;
  isTeamManagementUser?: boolean;
  teamUserId?: string;
  isCompany?: boolean;
};

// using same interface for actionsMenu
export interface PopperMenuState {
  showPopper: boolean;
  anchorEl: HTMLDivElement | SVGSVGElement | null | any;
}
const ProductDataGrid = ({
  tab,
  isChildComponent,
  isRerun = false,
  isTeamManagementUser = false,
  teamUserId = "",
  isCompany = false,
}: ProductDataGridProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const tags = useSelector(UserProductTagsSelector);
  const dbUser = useSelector(DbUserSelector);
  const userRole = useSelector(UserRoleSelector);
  const products = useSelector(SavedProductsSelector);
  const productsPagination = useSelector(ProductsPaginationSelector);
  const searchQuery = useSelector(ProductSearchFilterSelector);

  const { onOpen } = useContext(PricingContext);
  const totalProducts = useSelector((state: AppState) => state.product.totalProducts);
  const brand = location?.state?.productBrand;
  const [state, setState] = useState<ProductDataGridState>({
    loading: true,
    openDialogBox: false,
    openDeleteConfirm: false,
    currentDeleteId: "",
  });
  const [openMultipleDeleteConfirm, setOpenMultipleDeleteConfirm] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<Product[]>([]);
  const [search, setSearch] = useState<string>(searchQuery);
  //const [searchQuery, setSearchQuery] = useState<string>("");
  const { pageSize, setPageNumber, pageNumber, setPageSize, offset, sortOrder, sortBy, setSortModel } = usePagination(
    100,
    true,
    productsPagination
  );
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [showToolbarOptions, setShowToolbarOptions] = useState<boolean>(false);
  const [tagOptions, setTagsOption] = useState<PopperMenuState>({
    showPopper: false,
    anchorEl: null,
  });
  const [selectedRerunProduct, setSelectedRerunProduct] = useState<any>();
  const [openRerun, setOpenRerun] = useState<boolean>(false);
  const [csvLoading, setCsvLoading] = useState<boolean>(false);
  const [productLiveFilter, setProductLiveFilter] = useState<any>(() => {
    const prevPublishStatus = localStorage.getItem("publishStatus");
    return prevPublishStatus ? prevPublishStatus : contentFilters.all;
  });
  const [hideInternalUsersProducts, setHideInternalUsersProducts] = useState<boolean>(() => {
    const storedValue = localStorage.getItem("hideInternalUsersProducts");
    return storedValue ? JSON.parse(storedValue) : false;
  });
  
  const [showApprovedProducts, setShowApprovedProducts] = useState<boolean>(() => {
    const storedValue = localStorage.getItem("showApprovedProducts");
    return storedValue ? JSON.parse(storedValue) : false;
  });

  // for filter
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [refreshWaitingGrid, setRefreshWaitingGrid] = React.useState<boolean>(false);
  const [openExportDialog, setOpenExportDialog] = useState<boolean>(false);
  const [openAllCsvExportDialog, setOpenAllCsvExportDialog] = useState<boolean>(false);

  const companyRole = useSelector(CompanyRoleSelector);

  const handleCsvExport = (exportType: string) => {
    if (!csvLoading) {
      setCsvLoading(true);
      downloadSavedProductCsv({
        showProtectedFields: showUserEmailInProductsPage(dbUser?.role),
        brand,
        exportType,
        teamUserID: teamUserId ? teamUserId : isTeamManagementUser ? dbUser._id : null,
        isCompany:
          isCompany ||
          (dbUser?.companyID &&
            (dbUser?.role === RolesEnum.USER || dbUser?.role === RolesEnum.COMPANY_ADMIN) &&
            !teamUserId &&
            !brand),
        companyID: isCompany
          ? teamUserId
          : dbUser?.companyID && (dbUser?.role === RolesEnum.USER || dbUser?.role === RolesEnum.COMPANY_ADMIN)
          ? dbUser?.companyID
          : "",
        filters: getFilters(),
      })
        .then(() => {
          toast.success(SUCC_CSV_EXPORT);
          setCsvLoading(false);
        })
        .catch((error: any) => {
          toast.error(errorHandler(error));
          setCsvLoading(false);
        });
    } else {
      toast.warn("Exporting Data. Please Wait");
    }
  };
  const handleExportAllCsvClick = () => setOpenAllCsvExportDialog(true);

  const handleSelectedCsvExport = (exportType: string, singleProduct: null | Product = null) => {
    if (!csvLoading) {
      let productAsinsAndUserIds: any[] = [];
      if (singleProduct) {
        productAsinsAndUserIds = [
          {
            productASIN: singleProduct.productASIN,
            userID: singleProduct.userID,
          },
        ];
      } else {
        productAsinsAndUserIds = selectedRows.map((product) => ({
          productASIN: product.productASIN,
          userID: product.userID,
        }));
      }
      setCsvLoading(true);
      downloadSelectedProductsCsv({
        payload: productAsinsAndUserIds,
        exportType: exportType,
        showProtectedFields: showUserEmailInProductsPage(dbUser?.role),
        bulletPointsFormat: dbUser?.bulletPointsFormat,
      })
        .then(() => {
          toast.success("CSV export successful.");
          setCsvLoading(false);
          setSelectedRows([]);
          setSelectionModel([]);
        })
        .catch((error: any) => {
          toast.error(errorHandler(error));
          setCsvLoading(false);
        });
    } else {
      toast.warn("Exporting Data. Please Wait");
    }
  };

  const debouncedSearch = useRef(
    debounce((value: string) => {
      dispatch(setProductSearchFilterDispatch(value));
    }, 1500)
  ).current;

  const getProducts = async ({ filters, navigate }: any) => {
    setState({ ...state, loading: true });
    dispatch(
      getUserProductAction({
        filters,
        navigate,
        isTeamManagementUser,
        teamUserId,
        isCompany,
      })
    )
      .then(() => {
        setTimeout(() => {
          setState({ ...state, loading: false });
        }, 2000);
      })
      .catch((e: any) => {
        toast.error(errorHandler(e));
        setState({ ...state, loading: false });
      });
  };
  const getFilters = (): any => {
    let filters: any = {
      offset,
      limit: pageSize,
      searchQuery: searchQuery,
      isReviewPage: tab === TabsEnum.REVIEW,
      hideInteralUsersProduct: false,
      searchFilter: "",
      isApprovedProducts : false
    };
    if (sortBy !== "") {
      filters = {
        ...filters,
        sortBy,
        sortOrder,
      };
    }

    if (productLiveFilter) {
      filters = {
        ...filters,
        searchKey: productLiveFilter === contentFilters.all ? "" : "productLive.isProductLive",
        searchFilter: productLiveFilter === contentFilters.all ? "" : productLiveFilter,
      };
    }
    if (searchQuery) {
      filters = {
        ...filters,
        searchQuery,
      };
    }

    if (brand) {
      filters = {
        ...filters,
        productBrand: brand === "Others" ? undefined : brand,
      };
    }

    if (hideInternalUsersProducts) {
      filters = {
        ...filters,
        hideInteralUsersProduct: hideInternalUsersProducts,
      };
    }
    
    if (showApprovedProducts) {
      filters = {
        ...filters,
        isApprovedProducts : showApprovedProducts,
      };
    }

    return filters;
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    return () => {
      dispatch(setProductSearchResetDispatch());
    };
  }, []);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (selectedRows?.length) setShowToolbarOptions(true);
    else setShowToolbarOptions(false);
  }, [selectedRows]);

  /* eslint-disable react-hooks/exhaustive-deps */
  //useEffect to retain the state of pagination
  useEffect(() => {
    dispatch(
      setProductsPaginationDispatch({
        pageNumber,
        pageSize,
        offset,
        sortBy,
        sortOrder,
      })
    );
  }, [pageNumber, pageSize, offset, sortBy, sortOrder, searchQuery]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(savedProductsDispatch([]));
    // to clear the state
    dispatch(setChangeKeywordsProductOwnerIdDispatch(null));
    localStorage.removeItem("productOwnerId");
    localStorage.removeItem("isGenericVariation");
  }, []);

  const isInitialRender = useRef(true);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!isInitialRender.current) {
      getProducts({ filters: getFilters(), navigate });
    } else {
      isInitialRender.current = false;
    }
  }, [pageNumber, pageSize, offset, sortBy, sortOrder, searchQuery, productLiveFilter, hideInternalUsersProducts, showApprovedProducts]);

  const handleDelete = async (id: string) => setState({ ...state, openDeleteConfirm: true, currentDeleteId: id });
  const handleMultipleDelete = () => setOpenMultipleDeleteConfirm(true);
  const closeConfirmDelete = () => {
    if (state.openDeleteConfirm) setState({ ...state, openDeleteConfirm: false, currentDeleteId: "" });
    else if (openMultipleDeleteConfirm) setOpenMultipleDeleteConfirm(false);
  };
  const handleCellClick = async (gridCellParams: GridCellParams) => {
    if (gridCellParams.field === "productASIN" && !state.loading) {
      if (gridCellParams.row?.status === ContentStatusEnum.GENERATING) {
        setState({ ...state, openDialogBox: true });
        setTimeout(() => {
          setState({ ...state, openDialogBox: false });
        }, 5000);
      } else if (tab === TabsEnum.REVIEW)
        navigatePage(
          `${URL_PRODUCT_CATALOG}/${CatalogEnum.REVIEW_PAGE}/${gridCellParams.row?.productASIN}/${gridCellParams.row?.userID}`,
          navigate
        );
      else {
        navigatePage(
          `${URL_PRODUCT_CATALOG}/${CatalogEnum.DETAIL_PAGE}/${gridCellParams.row?.productASIN}/${gridCellParams.row?.userID}`,
          navigate,
          {
            state: {
              isChildComponent: isTeamManagementUser
                ? location.pathname
                : isChildComponent
                ? TabsEnum.SAVED_ASIN
                : TabsEnum.SAVED,
            },
          }
        );
      }
    }
  };
  const closeRerunDialog = () => setOpenRerun(false);
  const confirmDeleteProduct = async () => {
    if (state.openDeleteConfirm) {
      setState({ ...state, loading: true });
      generateProductApis
        .deleteGeneratedProducts([state.currentDeleteId])
        .then(() => {
          let filteredProducts = products.filter((product: Product) => product._id !== state.currentDeleteId);
          dispatch(savedProductsDispatch(filteredProducts));
          toast.success(SUCC_DELETE_PRODUCT);
          closeConfirmDelete();
        })
        .catch((e) => {
          setState({ ...state, loading: false });
          closeConfirmDelete();
          getExceptionError(e);
        });
    } else if (openMultipleDeleteConfirm) {
      setState({ ...state, loading: true });
      try {
        await generateProductApis.deleteGeneratedProducts(selectedRows.map((p) => p._id));

        getProducts({ filters: getFilters(), navigate });
        setSelectedRows([]);
        setSelectionModel([]);
        setOpenMultipleDeleteConfirm(false);
        toast.success("Your product has been deleted successfully");
        setState({ ...state, loading: false });
      } catch (e) {
        errorHandler(e);
        setState({ ...state, loading: false });
        setOpenMultipleDeleteConfirm(false);
      }
    }
  };
  const reGenerateKeywords = async (product: any) => {
    if (product.row.productType === ProductFetchType.GOGO) {
      ProductApis.gogoAutomatoProductGeneration({
        userID: product.row.userID,
        domain: product.row.domain,
        asin: product.row.productASIN,
        isRerun: true,
        updateCompetitors: product.row.status === ContentStatusEnum.ERROR,
      });
      setTimeout(() => {
        closeRerunDialog();
        getProducts({ filters: getFilters(), navigate });
      }, 1000);
    } else {
      try {
        setState({ ...state, loading: true });
        const keywords = await KeywordsApis.getKeywords({
          productAsin: product.row?.productASIN,
          userID: product.row?.userID,
        });

        const titleKeywords =
          keywords?.titleKeywords && keywords?.titleKeywords?.map((keyword: any) => createKeywordObject(keyword));
        const otherKeywords =
          keywords?.otherKeywords && keywords?.otherKeywords?.map((keyword: any) => createKeywordObject(keyword));

        const reGenPayload = {
          productASIN: product.row?.productASIN,
          titleKeywords: titleKeywords,
          otherKeywords: otherKeywords,
          searchTermKeywords: [
            ...(keywords?.selectedHelium10Keywords?.map((keyword: any) => keyword.phrase) || []),
            ...(keywords?.otherKeywords?.map((keyword: any) => keyword.phrase) || []),
          ].filter(Boolean),
          userID: product.row?.userID,
          isRerun: true,
        };

        generateProductUsingOpenAIByKeywordsTitleBulletPointsAndDescription(reGenPayload)
          .then(() => {
            closeRerunDialog();
            setTimeout(() => {
              getProducts({ filters: getFilters(), navigate });
              setState({ ...state, loading: false });
            }, 1000);
          })
          .catch((e) => {
            toast.error(errorHandler(e));
            setState({ ...state, loading: false });
          });
      } catch (e: any) {
        toast.error(errorHandler(e));
        setState({ ...state, loading: false });
      }
    }
  };

  const handleRegeneration = async (product: any) => {
    closeRerunDialog();
    if (product?.row?.status === ContentStatusEnum.ERROR) {
      const helium10LimitReached = product?.row?.companyID
        ? await checkIsCompanyReachedHelium10Limit(product.row?.companyID)
        : await checkIsUserReachedHisHelium10Limit(product?.row?.userID);
      if (helium10LimitReached) {
        if (dbUser?._id === product.row.userID) {
          toast.warn(<ToastError error={UnlockGreaterPossibilities} />);
          onOpen();
        } else if (dbUser?._id !== product.row.userID) {
          toast.error(UserReachedRegenLimit);
        } else {
        }
      } else {
        reGenerateKeywords(product);
      }
    } else {
      const isLimitReached = product?.row?.companyID
        ? await checkIsCompanyReachedHeliumOrContentRegenLimit(product.row?.companyID)
        : await checkIsProductOwnerReachedHisLimit(product?.row?.userID);
      if (isLimitReached) {
        if (dbUser?._id === product.row.userID) {
          toast.warn(<ToastError error={UnlockGreaterPossibilities} />);
          onOpen();
        } else if (dbUser?._id !== product.row.userID) {
          toast.error(UserReachedRegenLimit);
        } else {
        }
      } else {
        reGenerateKeywords(product);
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearch(e.target.value);
    debouncedSearch(e.target.value);
  };
  const onSelectedRows = (e: GridSelectionModel) => {
    const selectedIDs = new Set(e);
    setSelectedRows(products.filter((row: any) => selectedIDs.has(row.id)));
    setSelectionModel(e);
  };
  const handleProductLive = () => {
    if (selectedRows?.length) {
      const approved = selectedRows?.filter(
        (pro) => pro?.status !== ContentStatusEnum.GENERATING && pro?.status !== ContentStatusEnum.ERROR
      );
      if (approved.length <= checkLiveProductLimit) {
        setState({ ...state, loading: true });
        dispatch(
          checkSelectedProductsLiveActions(
            approved.map((r) => ({ productASIN: r?.productASIN || "", userID: r?.userID || "" }))
          )
        )
          .then(() => {
            getProducts({ filters: getFilters(), navigate });
            setSelectedRows([]);
            setSelectionModel([]);
          })
          .catch((e: any) => {
            errorHandler(e);
            setState({ ...state, loading: false });
          });
      } else {
        toast.warn(`Cannot select more than ${checkLiveProductLimit} products`);
      }
    } else {
      toast.warn("No Approved Product Selected");
    }
  };
  const handleAddTag = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setTagsOption({ ...tagOptions, showPopper: true, anchorEl: e.currentTarget });
  };

  const handleFilterChange = (value: string, showInternalProduct: boolean, showApprovedChecked: boolean) => {
    if (localStorage.getItem("hideInternalUsersProducts") || localStorage.getItem("publishStatus") || localStorage.getItem("showApprovedProducts")) {
      localStorage.removeItem("hideInternalUsersProducts");
      localStorage.removeItem("publishStatus");
      localStorage.removeItem("showApprovedProducts");
    }

    try {
      setHideInternalUsersProducts(showInternalProduct);
      setShowApprovedProducts(showApprovedChecked)
      localStorage.setItem("hideInternalUsersProducts", JSON.stringify(showInternalProduct));
      localStorage.setItem("publishStatus", value);
      localStorage.setItem("showApprovedProducts", JSON.stringify(showApprovedChecked));
      const filter: string = value;
      setProductLiveFilter(filter);
    } catch (e: any) {
      toast.error(e);
    }
  };

  const handleFilterClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setShowFilterMenu(!showFilterMenu);
    setAnchorEl(e.currentTarget);
  };
  const handleFilterClose = () => {
    setShowFilterMenu(false);
    setAnchorEl(null);
  };
  const handleRefresh = () => {
    setRefreshWaitingGrid(true);
    getProducts({ filters: getFilters(), navigate });
  };

  const handlePublishOnAmazonClick = () => {
    console.log("publish");
  };

  const productsColumns: GridColDef[] = [];
  productsColumns.push({
    field: "actions",
    headerName: "",
    width: 110,
    sortable: false,
    renderCell: (params: GridCellParams<Product>) => {
      const showRegenerateOption =
        (showRerunButton(userRole as RolesEnum, params.row?.status as ContentStatusEnum) ||
          userRoles.isDomainUser(dbUser?.email || "") ||
          (dbUser?.companyID && companyRole === RolesEnum.PAID)) &&
        params.row?.status !== ContentStatusEnum.GENERATING &&
        isRerun;

      const showDeleteOption =
        showDeleteIconOnProductPage(
          dbUser?.role,
          params.row?.userEmail,
          dbUser?.email
          // companyRole
        ) && params.row?.status !== ContentStatusEnum.GENERATING;

      const showChangeKeywordOption =
        showChangeKeywordButton(params?.row, dbUser) &&
        params?.row?.status !== ContentStatusEnum.ERROR &&
        !state.loading
          ? true
          : false;

      const actionsMenu = (
        <>
          <ProductActionsMenu
            showRegenerateOption={showRegenerateOption}
            showDeleteOption={showDeleteOption}
            showChangeKeywordOption={showChangeKeywordOption}
            showPublishOption={false}
            showExportCsvOption={true && params?.row?.status !== ContentStatusEnum.ERROR && !state.loading}
            handleRegenerateClick={() => {
              setSelectedRerunProduct(params);
              setOpenRerun(true);
            }}
            handleDeleteClick={() => handleDelete(params.row?._id)}
            handleChangeKeywordClick={() => handleChangeKeywordsClick(params.row, dispatch, navigate, location, params?.row?.genericVariation)}
            handlePublishOnAmazonClick={handlePublishOnAmazonClick}
            handleExportCsvClick={() => handleSelectedCsvExport(exportCsvType.row, params.row)}
            disableRegenerationOption={params?.row?.genericVariation}
          />
        </>
      );
      return (
        <>
          {dbUser.companyID && productActionsMenuOnSavedProductForCompany(params.row?.status, companyRole) && (
            <>{actionsMenu}</>
          )}

          {!dbUser.companyID && productActionsMenuOnSavedProduct(params.row?.status, dbUser?.role) && (
            <>{actionsMenu}</>
          )}
          <>
            {showChangeKeywordButton(params?.row, dbUser) &&
              params?.row?.status !== ContentStatusEnum.ERROR &&
              !state.loading && (
                <Tooltip title={"Change Keywords"} placement="top">
                  <IconButton onClick={() => handleChangeKeywordsClick(params.row, dispatch, navigate, location, params?.row?.genericVariation)}>
                    <img src={changeKeywordIconActive} alt="change-keyword" style={{ width: "24px" }} />
                  </IconButton>
                </Tooltip>
              )}
          </>
        </>
      );
    },
  });
  productsColumns.push({
    field: "productASIN",
    headerName: "ASIN",
    width: 160,
    sortingOrder: ["desc", "asc"],
    renderCell: (params: GridCellParams<Product>) => (
      <div className="d-flex justify-content-between align-items-center w-100">
        <div
          style={{
            pointerEvents: "all",
            cursor: state.loading ? "not-allowed" : "pointer",
          }}
          onClick={() => handleCellClick(params)}
          onContextMenu={(e) => copyAsinByRightClick(e, params.row.productASIN)}
        >
          <div className={"d-flex align-items-center"}>
            <div className={`${classes.ASIN} HoverEffectForLinks`}>{params.row.productASIN}</div>
          </div>
        </div>
        <div style={{ cursor: "pointer", zIndex: 200 }}>
          {!state.loading && params.row?.status !== ContentStatusEnum.GENERATING && (
            <Tooltip title={"Open in new tab"} placement="top">
              <Link
                to={`${URL_PRODUCT_CATALOG}/${CatalogEnum.DETAIL_PAGE}/${params.row?.productASIN}/${params.row?.userID}`}
                target="_blank"
              >
                <IconButton>
                  <ArrowOutwardIcon htmlColor="#2e2e2e" fontSize="medium" />
                </IconButton>
              </Link>
            </Tooltip>
          )}
        </div>
      </div>
    ),
  });
  productsColumns.push({
    field: "title",
    headerName: "Title",
    width: 250,
    sortingOrder: ["desc", "asc"],
    renderCell: (_params: GridCellParams) => {
      const titleToShow = _params?.row?.title;

      return (
        <div className={"d-flex align-items-center"}>
          <img
            src={reduceImgSize(_params.row.imageURL) || _params.row.imageURL || defaultImage}
            onError={(e) => onImgErrorHandler(e, defaultImage)}
            alt={_params.row.productASIN}
            className={classes.Image}
          />
          <div>
            <div className={classes.CellLinesLimitContainer} title={titleToShow}>
              {titleToShow}
            </div>
            {_params.row?.status !== ContentStatusEnum.ERROR &&
              _params.row?.status !== ContentStatusEnum.GENERATING && (
                <div className={"d-flex align-items-center gap-3"}>
                  {(_params?.row?.isProductLiveBeingChecked !== undefined || _params?.row?.productLive) && (
                    <div className={"my-1"}>
                      <ProductStatus
                        type={
                          _params?.row?.isProductLiveBeingChecked
                            ? ProductLiveStatusEnum.CHECKING
                            : _params?.row?.productLive?.isProductLive
                        }
                        variant={"small"}
                      />
                    </div>
                  )}

                  {!!getAllTagsOfProduct(tags, _params.row?._id)?.length && (
                    <MuiTooltip
                      arrow
                      content={
                        <div className={"d-flex align-items-center p-2 flex-wrap gap-2"}>
                          {getAllTagsOfProduct(tags, _params.row?._id)?.map((tag) => (
                            <div
                              key={tag?._id}
                              style={{ backgroundColor: tag.color, color: "white" }}
                              className={`${classes.TagContainer}`}
                            >
                              {tag?.name}
                            </div>
                          ))}
                        </div>
                      }
                    >
                      <div className={`d-flex align-items-center ${classes.Tag} cursor-pointer`}>
                        <LocalOfferOutlinedIcon fontSize={"small"} />
                        {getAllTagsOfProduct(tags, _params.row?._id)?.length}
                      </div>
                    </MuiTooltip>
                  )}
                </div>
              )}
          </div>
        </div>
      );
    },
  });
  productsColumns.push({
    field: "domain",
    headerName: "Marketplace",
    width: 120,
    sortingOrder: ["desc", "asc"],
    renderCell: (_params: GridCellParams) => (
      <div>
        {(() => {
          const countryDomainObject = getCountryDomainObjectByName(
            _params.row?.domain || CountryDomainEnum.AMAZON_USA
          ) as CountryDomainObject;

          return (
            <div className={`d-flex align-items-center`}>
              <img src={countryDomainObject.flag} alt={`${countryDomainObject.name} Flag`} className={classes.Flag} />
              <MTypography variant={"subtitle2"} customClass={classes.FlagName}>
                {countryDomainObject.marketPlaceName}
              </MTypography>
            </div>
          );
        })()}
      </div>
    ),
  });
  productsColumns.push({
    field: "productBrand",
    headerName: "Brand",
    width: 110,
    sortingOrder: ["desc", "asc"],
    renderCell: (_params: GridCellParams) => (
      <div title={_params.value} className={classes.CellLinesLimitContainer}>
        {capitalizeEachLetter(_params.value)}
      </div>
    ),
  });
  if (showUserEmailInProductsPage(userRole as RolesEnum) || dbUser?.companyID) {
    productsColumns.push({
      field: "userEmail",
      headerName: "User",
      hideable: true,
      width: 210,
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <>
          {userRole !== RolesEnum.USER || dbUser?.companyID ? (
            <div title={params.row.userEmail} className={classes.CellLinesLimitContainer}>
              {params.row.userEmail}
            </div>
          ) : (
            <div></div>
          )}
        </>
      ),
    });
  }
  productsColumns.push({
    field: "status",
    headerName: "Status",
    width: 220,
    sortingOrder: ["desc", "asc"],
    renderCell: (_params: GridCellParams) => (
      <>
        <ProductStatus type={_params.row.status} showInfo />
      </>
    ),
  });
  productsColumns.push({
    field: "lastProductGeneratedAt",
    headerName: "Last version generated",
    width: 210,
    sortingOrder: ["desc", "asc"],
    renderCell: (_params: GridCellParams) => (
      <div title={_params.value} className={classes.CellLinesLimitContainer}>
        <>
          {toDateAndTimeObject(_params.value).date} <br />@ {toDateAndTimeObject(_params.value).time}
        </>
      </div>
    ),
  });

  const handleExportAsinTypeClick = (exportType: string) => {
    setOpenExportDialog(false);
    handleSelectedCsvExport(exportType, null);
  };

  const handleProductHistoryCsvExport = () => {
    setCsvLoading(true);
    generateProductApis
      .emailProductsHistory()
      .then(() => {
        toast.success(SUCC_CSV_EXPORT);
      })
      .catch((error: any) => {
        toast.error(errorHandler(error));
      })
      .finally(() => {
        setCsvLoading(false);
      });
  };

  const handleExportOptionClick = (exportType: string) => {
    if (products?.length > 0) {
      if (exportType === exportAllProductCsvType.all) {
        handleCsvExport("row");
      } else {
        handleProductHistoryCsvExport();
      }
    } else {
      toast.error(ERR_CSV_EXPORT);
    }
    setOpenAllCsvExportDialog(false);
  };

  return (
    <div className={isChildComponent || isTeamManagementUser ? "" : `${classes.Container}`}>
      <div className={"d-flex justify-content-between align-items-center mb-3"}>
        <MTextField
          icon={"search"}
          position={"start"}
          onChange={handleChange}
          name={"customKeyword"}
          placeholder={
            (showUserEmailInProductsPage(userRole as RolesEnum) || dbUser?.companyID) && !isTeamManagementUser
              ? "Search products by ASIN, Title or Email"
              : isTeamManagementUser && teamUserId && isCompany
              ? "Search products by ASIN, Title or Email"
              : "Search products by ASIN or Title"
          }
          value={search}
          margin={"dense"}
          rootClass={classes.TextInput}
        />
        {showToolbarOptions ? (
          <div className={`${classes.ToolbarsContainer} d-flex align-items-center gap-3`}>
            {(showMultipleProductDeleteButton(dbUser?.role) ||
              showMultipleProductDeleteButtonCompany(dbUser?.role, companyRole)) && (
              <MTypography variant={"body2"} customClass={classes.ToolbarOptions}>
                <div onClick={handleMultipleDelete} className={"d-flex align-items-center cursor-pointer"}>
                  <DeleteIcon className={classes.ToolbarIcon} />
                  {"Delete"}
                </div>
              </MTypography>
            )}
            <MTypography variant={"body2"} customClass={classes.ToolbarOptions}>
              <div onClick={handleProductLive} className={"d-flex align-items-center cursor-pointer "}>
                <OnlinePredictionOutlinedIcon className={classes.ToolbarIcon} />
                {"Check Product Live"}
              </div>
            </MTypography>
            {showAddTagOption(dbUser?.role) && (
              <MTypography variant={"body2"} customClass={classes.ToolbarOptions}>
                <>
                  <MuiPopover
                    open={tagOptions.showPopper}
                    anchorEl={tagOptions.anchorEl}
                    onClose={() => setTagsOption({ ...tagOptions, showPopper: false })}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <TagPopper selectedProducts={selectedRows} />
                  </MuiPopover>
                  <div onClick={handleAddTag} className={"d-flex align-items-center cursor-pointer"}>
                    <LocalOfferOutlinedIcon className={classes.ToolbarIcon} />
                    {"Add a Tag"}
                  </div>
                </>
              </MTypography>
            )}
            {!isExportButton(userRole, dbUser?.companyID, companyRole) && (
              <MTypography variant={"body2"} customClass={classes.ToolbarOptions}>
                <div onClick={() => setOpenExportDialog(true)} className={"d-flex align-items-center cursor-pointer"}>
                  <FileDownloadOutlinedIcon fontSize={"medium"} />
                  {"Export"}
                </div>
              </MTypography>
            )}
          </div>
        ) : (
          <div className={`d-flex align-items-center justify-content-end ${state.loading ? "gap-4" : "gap-2"}`}>
            <div
              className={`d-flex align-items-center gap-1 justify-content-between cursor-pointer ${
                state.loading ? `pe-none ${classes.DisabledText}` : "pe-auto HoverEffectForButton"
              }`}
              onClick={handleRefresh}
            >
              <CachedIcon />
              <MTypography variant={"subtitle1"} color="inherit">
                {"Refresh"}
              </MTypography>
            </div>
            <div>
              <div
                className={`d-flex align-items-center gap-1 justify-content-between cursor-pointer ${
                  state.loading ? `pe-none ${classes.DisabledText}` : "pe-auto HoverEffectForButton"
                }`}
                onClick={handleFilterClick}
              >
                <FilterListIcon fontSize={"medium"} />
                <MTypography variant={"subtitle1"} color="inherit">
                  {"Filters"}
                </MTypography>
              </div>
              <FilterMenu
                open={showFilterMenu}
                anchorEl={anchorEl}
                onClose={handleFilterClose}
                options={contentFiltersOption}
                handleFilterChange={handleFilterChange}
                hideInternalUsersProducts={hideInternalUsersProducts}
                showApprovedProducts={showApprovedProducts}
                productLiveFilter={productLiveFilter}
                hideProductVisibilityFilter={!!teamUserId}
              />
            </div>
            <div
              className={`d-flex align-items-center gap-1 cursor-pointer ${
                state.loading || isExportButton(userRole, dbUser?.companyID, companyRole)
                  ? `pe-none ${classes.DisabledText}`
                  : "pe-auto HoverEffectForButton"
              }`}
              // onClick={() => handleCsvExport("row")}
              onClick={handleExportAllCsvClick}
            >
              {!csvLoading && <FileDownloadOutlinedIcon fontSize={"medium"} />}
              <MTypography variant={"subtitle1"} color="inherit">
                {csvLoading ? "Exporting..." : "Export"}
              </MTypography>
            </div>
          </div>
        )}
      </div>
      {(!isTeamManagementUser || showWaitingGridOnClientsForCompany(dbUser?.role)) && !brand && (
        <WaitingGrid
          tab={TabsEnum.SAVED}
          isChildComponent={false}
          refreshWaitingGrid={refreshWaitingGrid}
          setRefreshWaitingGrid={setRefreshWaitingGrid}
          teamUserId={teamUserId}
          isCompany={isCompany}
        />
      )}
      {state.loading ? (
        <GridSkeleton />
      ) : (
        <ProductTable
          total={totalProducts}
          pageSize={pageSize}
          setPageNumber={setPageNumber}
          pageNumber={pageNumber}
          setPageSize={setPageSize}
          columns={productsColumns}
          rows={products ?? []}
          initialState={{
            columns: {
              columnVisibilityModel: {
                userEmail: showUserEmailInProductsPage(dbUser?.role),
              },
            },
          }}
          sortModel={[
            {
              field: sortBy || "title",
              sort: sortOrder ? (sortOrder === 1 ? ("asc" as any) : ("desc" as any)) : "asc",
            },
          ]}
          // onCellClick={handleCellClick}
          setSortModel={setSortModel}
          onSelectionModelChange={onSelectedRows}
          selectionModel={selectionModel}
          checkboxSelection
          isRowSelectable={(params) =>
            params.row.status !== ContentStatusEnum.ERROR && params.row.status !== ContentStatusEnum.GENERATING
          }
        />
      )}

      <KeywordsPageDialog
        open={state.openDialogBox}
        onClose={() => {
          setState({ ...state, openDialogBox: false });
        }}
        title={generatingProcessTimeTenToFifteen}
      />
      <ActionBox
        handleAction={confirmDeleteProduct}
        handleBack={closeConfirmDelete}
        open={state.openDeleteConfirm || openMultipleDeleteConfirm}
        actionText={"Delete"}
        message={
          state.openDeleteConfirm ? "Do you want to delete your product?" : "Do you want to delete selected products?"
        }
        title={state.openDeleteConfirm ? "Confirm Delete Product" : "Confirm Delete Selected Products"}
        backText={"Cancel"}
      />
      <ActionBox
        handleAction={() => handleRegeneration(selectedRerunProduct)}
        handleBack={closeRerunDialog}
        open={openRerun}
        actionText={"Confirm"}
        message={"Are you sure you want to regenerate your product?"}
        title={"Confirm Regenerate Product"}
        backText={"Cancel"}
      />

      <ExportAsinsDialoge
        open={openExportDialog}
        onClose={() => setOpenExportDialog(false)}
        handleTypeClick={handleExportAsinTypeClick}
        title="Export"
      />

      <ExportAllAsinsDialoge
        open={openAllCsvExportDialog}
        onClose={() => setOpenAllCsvExportDialog(false)}
        handleTypeClick={handleExportOptionClick}
        title="Export"
      />
    </div>
  );
};

export default memo(ProductDataGrid);

import React from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import classes from './CheckboxFilters.module.css'


export const bsrPerformanceCheckBoxValues = {
  bsr:'bsr',
  conversationRate:"conversionRate",
  traffic:"sessions",
  unitsSold:"unitsSold",
}

const checkBoxes: any = [
  { name: "BSR", value: bsrPerformanceCheckBoxValues.bsr, color: "#F94144",color1:'#D87B00' },
  { name: "Conversion Rate %", value: bsrPerformanceCheckBoxValues.conversationRate, color: "#4285F4",color1:'#90BE6D' },
];

interface CheckboxFiltersProps {
  setCheckbox: any;
  selected: string
}

const CheckboxFilters: React.FC<CheckboxFiltersProps> = ({ setCheckbox, selected }) => {
  // const [selected, setSelected] = useState<string[]>([]);


  // const handleCheckboxes = (value: string):any => {
  //   if(value === bsrPerformanceCheckBoxValues.bsr)
  //     return false
  //   if (isChecked(value)) {
  //     const filteredCheckboxes = selected.filter((item: string) => item !== value);
  //     setCheckbox(filteredCheckboxes);
  //   } else {
  //     if(selected.length >=2){
  //       return false
  //     }
  //     setCheckbox([...selected, value]);
  //   }
  // };
  console.log(selected,setCheckbox);


  return (
    <div className={"d-flex gap-1 my-1"}>
      <RadioGroup row className={"d-flex"} onChange={(e: any) => setCheckbox(e.target.value)} value={selected}>
        {checkBoxes.map((checkbox: any) => (
          <FormControlLabel
            key={checkbox.name}
            value={checkbox.value}
            control={<Radio />}
            label={
              <div className={"d-flex align-items-center gap-2"}>
                <div
                  className={classes.Dot}
                  style={{
                    backgroundColor: checkbox.color,
                    opacity: selected.length === 2 && !selected.includes(checkbox.value) ? 0.5 : 1,
                  }}
                ></div>
                <div
                  className={classes.Text}
                  style={{ opacity: selected.length === 2 && !selected.includes(checkbox.value) ? 0.5 : 1 }}
                >
                  {checkbox.value === bsrPerformanceCheckBoxValues.bsr ? 'BSR vs' : 'Traffic vs'}
                </div>

                <div
                  className={classes.Dot}
                  style={{
                    backgroundColor: checkbox.color1,
                    opacity: selected.length === 2 && !selected.includes(checkbox.value) ? 0.5 : 1,
                  }}
                ></div>
                <div
                  className={classes.Text}
                  style={{ opacity: selected.length === 2 && !selected.includes(checkbox.value) ? 0.5 : 1 }}
                >
                  {checkbox.value === bsrPerformanceCheckBoxValues.bsr ? 'Units Sold' : 'Conversation Rate'}
                </div>
              </div>
            }
          />
        ))}
      </RadioGroup>

      {/*{checkBoxes.map((checkbox: any) => (*/}
      {/*  <div key={checkbox.value} className={"d-flex align-items-center gap-1"}>*/}
      {/*    {checkbox.value === bsrPerformanceCheckBoxValues.bsr ? (*/}
      {/*      <Checkbox disabled checked checkedIcon={<CheckBoxIcon className={classes.CheckBoxIconFontSize} />} />*/}
      {/*    ) : (*/}
      {/*      <MCheckbox*/}
      {/*        disabled={selected.length === 2 && !selected.includes(checkbox.value)}*/}
      {/*        variant={"square"}*/}
      {/*        checked={isChecked(checkbox.value)}*/}
      {/*        onClick={() => handleCheckboxes(checkbox.value)}*/}
      {/*      />*/}
      {/*    )}*/}
      {/*    <div className={classes.Dot} style={{ backgroundColor: checkbox.color, opacity: selected.length === 2 && !selected.includes(checkbox.value) ? 0.5 : 1 }}></div>*/}
      {/*    <div className={classes.Text} style={{  opacity: selected.length === 2 && !selected.includes(checkbox.value) ? 0.5 : 1 }}>{checkbox.name}</div>*/}
      {/*  </div>*/}
      {/*))}*/}
    </div>
  );
};

export default CheckboxFilters;

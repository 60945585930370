import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import MuiPopover from "../../../Atoms/Popover";
import MTypography from "../../../Atoms/MTypography";
import MuiTooltip from "../../../Atoms/MuiTooltip";

import changeKeywordIconActive from "../../../../assets/svgs/change-keyword-icon-active.svg";

import { useDeviceType } from "../../../../hooks/useDeviceType";

import { PopperMenuState } from "../ProductDataGrid";
import classes from "./index.module.css";
import { IconButton } from "@mui/material";

interface ProductActionsMenuProps {
  showRegenerateOption: boolean;
  showDeleteOption: boolean;
  showChangeKeywordOption: boolean;
  showPublishOption: boolean;
  showExportCsvOption: boolean;
  handleRegenerateClick: () => void;
  handleDeleteClick: () => void;
  handleChangeKeywordClick: () => void;
  handlePublishOnAmazonClick: () => void;
  handleExportCsvClick: () => void;
  disableRegenerationOption?: boolean;
}

const ProductActionsMenu: React.FC<ProductActionsMenuProps> = (props) => {
  const {
    showRegenerateOption = false,
    showDeleteOption = false,
    showChangeKeywordOption = false,
    showPublishOption = false,
    showExportCsvOption = false,
    handleRegenerateClick,
    handleDeleteClick,
    handleChangeKeywordClick,
    handlePublishOnAmazonClick,
    handleExportCsvClick,
    disableRegenerationOption = false,
  } = props;

  const { isDesktop } = useDeviceType();

  const [state, setState] = useState<PopperMenuState>({
    showPopper: false,
    anchorEl: null,
  });

  const onClose = () =>
    setState({
      showPopper: false,
      anchorEl: null,
    });

  return (
    <>
      <IconButton
        onClick={(e) => {
          setState({
            ...state,
            anchorEl: e.currentTarget,
            showPopper: true,
          });
        }}
      >
        <MoreVertIcon className={`${classes.IconColor}`} sx={{}} fontSize="medium" />
      </IconButton>
      <MuiPopover
        sx={{
          "& .MuiPaper-root": {
            // boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
            boxShadow:
              "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
          },
        }}
        open={state.showPopper}
        anchorEl={state.anchorEl}
        onClose={() => setState({ ...state, showPopper: false })}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className={"p-2"}>
          {showRegenerateOption && (
            <>
              {disableRegenerationOption ? (
                <MuiTooltip
                  arrow={false}
                  content={"Regeneration is temporarily disabled to maintain content accuracy for child variations."}
                  placement="right"
                >
                  <div
                    className={`${classes.ItemContainer} ${disableRegenerationOption ? `${classes.DisabledText}` : ""}`}
                  >
                    <RefreshIcon htmlColor={"inherit"} />
                    <MTypography variant={"subtitle1"} color={"inherit"}>
                      Regenerate
                    </MTypography>
                  </div>
                </MuiTooltip>
              ) : (
                <div
                  onClick={() => {
                    handleRegenerateClick();
                    onClose();
                  }}
                  className={`${classes.ItemContainer} ${
                    disableRegenerationOption ? `pe-none ${classes.DisabledText}` : ""
                  }`}
                >
                  <RefreshIcon htmlColor={disableRegenerationOption ? "inherit" : "#2e2e2"} />
                  <MTypography variant={"subtitle1"} color={disableRegenerationOption ? "inherit" : "#80828A"}>
                    Regenerate
                  </MTypography>
                </div>
              )}
            </>
          )}

          {!isDesktop && showChangeKeywordOption && (
            <div
              className={`${classes.ItemContainer}`}
              onClick={() => {
                handleChangeKeywordClick();
                onClose();
              }}
            >
              <img src={changeKeywordIconActive} alt="change-keyword" style={{ paddingLeft: "0px" }} />
              <MTypography variant={"subtitle1"} color={"#80828A"}>
                Change Keywords
              </MTypography>
            </div>
          )}

          {showPublishOption && false && (
            <div
              className={`${classes.ItemContainer}`}
              onClick={() => {
                handlePublishOnAmazonClick();
                onClose();
              }}
            >
              <OpenInNewRoundedIcon htmlColor="#2e2e2e" />
              <MTypography variant={"subtitle1"} color={"#80828A"}>
                Publish on Amazon
              </MTypography>
            </div>
          )}
          
          {showExportCsvOption && handleExportCsvClick && (
            <div
              className={`${classes.ItemContainer}`}
              onClick={() => {
                handleExportCsvClick();
                onClose();
              }}
            >
              <FileDownloadOutlinedIcon htmlColor="#2e2e2e" />
              <MTypography variant={"subtitle1"} color={"#80828A"}>
                Export CSV
              </MTypography>
            </div>
          )}

          {showDeleteOption && (
            <div
              className={`${classes.ItemContainer}`}
              onClick={() => {
                handleDeleteClick();
                onClose();
              }}
            >
              <DeleteIcon htmlColor="#2e2e2e" />
              <MTypography variant={"subtitle1"} color={"#80828A"}>
                Delete
              </MTypography>
            </div>
          )}
        </div>
      </MuiPopover>
    </>
  );
};

export default ProductActionsMenu;
